import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, Row, notification, Select, Spin, Table, DatePicker } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import PurchaseImage from '../../assets/images/vendor.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import moment from 'moment';
import PageTitle from '../common/PageTitle';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const { RangePicker } = DatePicker;

const PurchaseReport = () => {
    const navigate = useNavigate();
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
    const [purchaseReceiptList, setPurchaseReceiptList] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    useEffect(() => {
        setLoading(true);
        fetchVendorList();
    }, []);

    const formik = useFormik({
        initialValues: {
            vendor_id: '',
            from_date: '',
            to_date: ''
        },
        onSubmit: (values, { resetForm }) => {},
    });

    useEffect(() => {
        if (formik.values.vendor_id) {
            fetchPurchaseOrderList(formik.values.vendor_id);
            fetchPurchaseReceiptList(formik.values.vendor_id);
        } else {
            setPurchaseOrderList([]);
            setPurchaseReceiptList([]);
        }
    }, [formik.values.vendor_id]);

    useEffect(() => {
        if (purchaseOrderList.length || purchaseReceiptList.length) {
            const filteredData = [...purchaseOrderList, ...purchaseReceiptList].filter(item => {
                const itemDate = moment(item.date);
                return (!formik.values.from_date || itemDate.isSameOrAfter(formik.values.from_date, 'day')) &&
                       (!formik.values.to_date || itemDate.isSameOrBefore(formik.values.to_date, 'day'));
            });
            setReportData(filteredData);
        } else {
            setReportData([]);
        }
        setLoading(false);
    }, [purchaseOrderList, purchaseReceiptList, formik.values.from_date, formik.values.to_date]);

    const fetchPurchaseOrderList = (vendor_id) => {
        setLoading(true);
        httpCall({
            method: 'GET',
            url: `/api/purchase/purchaseOrderReport/list?vendor_id=${vendor_id}`,
          })
          
            .then((response) => {
                setPurchaseOrderList(response?.data.map(order => ({
                    ...order,
                    vch_type: 'PO',
                    vch_no: order.purchase_order_no,
                    date: order.purchase_order_date,
                    dweight: order.total_weight,
                    debit: 0,
                    cweight: 0,
                    credit: 0
                })) || []);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error retrieving purchase order data:', error);
            });
    };

    const fetchPurchaseReceiptList = (vendor_id) => {
        setLoading(true);
        httpCall({
            method: 'GET',
            url: `/api/purchase/purchaseReceipt/list?vendor_id=${vendor_id}`,
          })          
            .then((response) => {
                setPurchaseReceiptList(response?.data.map(receipt => ({
                    ...receipt,
                    vch_type: 'PR',
                    vch_no: receipt.purchase_receipt_no,
                    date: receipt.purchase_receipt_date,
                    dweight: 0,
                    debit: 0,
                    cweight: receipt.total_receipt_weight,
                    credit: receipt.amount_1_received + (receipt.amount_2_received || 0)
                })) || []);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error retrieving purchase receipt data:', error);
            });
    };

    const fetchVendorList = () => {
        httpCall({
            method: 'GET',
            url: '/api/vendor/get',
        })
            .then((response) => {
                if (response) {
                    setVendorList(response?.data.map(c => ({ label: c.company_name, value: c.id })));
                } else {
                    console.error('Error retrieving vendor list:', response.message);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error retrieving vendor list:', error);
                setLoading(false);
            });
    };

    const columnsNew = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: 150,
            sorter: (a, b) => moment(a.date) - moment(b.date),
            defaultSortOrder: 'ascend',
            render: (_, record) => moment(record.date).format('DD-MM-YYYY'),
        },
        {
            title: 'Particulars',
            dataIndex: 'vch_type',
            key: 'vch_type',
            align: 'center',
            width: 300,
            render: (_, record) => record.vch_type === 'PO' ? 'Purchase Order' : 'Purchase Receipt',
        },
        {
            title: 'Vch Type',
            dataIndex: 'vch_type',
            key: 'vch_type',
            align: 'center',
            width: 75,
            render: (_, record) => <span style={{ fontSize: '13px', fontWeight: '700' }}>{record.vch_type}</span>,
        },
        {
            title: 'Vch No',
            dataIndex: 'vch_no',
            key: 'vch_no',
            align: 'center',
            width: 100,
        },
        {
            title: 'Debit_Weight',
            dataIndex: 'dweight',
            key: 'dweight',
            align: 'center',
            width: 100,
            render: (_, record) => record.dweight > 0 ? record.dweight.toFixed(2) : '',
        },
        {
            title: 'Debit_Amount',
            dataIndex: 'debit',
            key: 'debit',
            align: 'center',
            width: 100,
            render: (_, record) => record.debit > 0 ? record.debit.toFixed(2) : '',
        },
        {
            title: 'Credit_Weight',
            dataIndex: 'cweight',
            key: 'cweight',
            align: 'center',
            width: 100,
            render: (_, record) => record.cweight > 0 ? record.cweight.toFixed(2) : '',
        },
        {
            title: 'Credit_Amount',
            dataIndex: 'credit',
            key: 'credit',
            align: 'center',
            width: 100,
            render: (_, record) => record.credit > 0 ? record.credit.toFixed(2) : '',
        }
    ];

    const mergedColumns = columnsNew.map((col) => ({ ...col, onCell: undefined }));

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const getTotal = (field) => {
        return reportData.reduce((total, item) => total + (item[field] || 0), 0);
    };

    const debitTotal = getTotal('debit');
    const creditTotal = getTotal('credit');
    const debitWeightTotal = getTotal('dweight');
    const creditWeightTotal = getTotal('cweight');

    const debitCreditDifference = Math.abs(debitTotal - creditTotal).toFixed(2);
    const weightDifference = Math.abs(debitWeightTotal - creditWeightTotal).toFixed(2);

    const highestPaymentColumn = debitTotal >= creditTotal ? 'debit' : 'credit';
    const highestWeightColumn = debitWeightTotal >= creditWeightTotal ? 'dweight' : 'cweight';

    return (
        <>
            <PageTitle
                imagesrc={PurchaseImage}
                pageTitle="Purchase Report"
                buttonList={[]}
            />
            {contextHolder}
            <div className='vendorListArea' style={{ overflow: '' }}>
                <Row gutter={16} className="gutter-row">
                    <Col span={12} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Vendor</span>
                        </div>
                        <Select
                            id="vendor_id"
                            showSearch
                            placeholder="Select Vendor"
                            style={{ width: '100%', height: '38px' }}
                            options={vendorList}
                            onChange={(e) => formik.setFieldValue("vendor_id", e)}
                        />
                    </Col>
                    <Col span={12} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Select Date Range</span>
                        </div>
                        <RangePicker
                            style={{ height: '38px' }}
                            onChange={(e) => {
                                if (e) {
                                    const start = e[0].format('YYYY-MM-DD');
                                    const end = e[1].format('YYYY-MM-DD');
                                    formik.setFieldValue("from_date", start);
                                    formik.setFieldValue("to_date", end);
                                } else {
                                    formik.setFieldValue("from_date", '');
                                    formik.setFieldValue("to_date", '');
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
                    <Table
                        dataSource={reportData}
                        size='small'
                        bordered
                        columns={mergedColumns}
                        rowKey="vch_no"
                        pagination={{ ...pagination, showSizeChanger: false }}
                        onChange={handleTableChange}
                        style={{ fontFamily: 'Poppins' }}
                        summary={() => (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={4} align="right">
                                        <strong>Total</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4} align="center">
                                        <strong>{debitWeightTotal.toFixed(2)}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5} align="center">
                                        <strong>{debitTotal.toFixed(2)}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6} align="center">
                                        <strong>{creditWeightTotal.toFixed(2)}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7} align="center">
                                        <strong>{creditTotal.toFixed(2)}</strong>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={4} align="right">
                                        <strong>Balance</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4} align="center">
                                        <strong>{highestWeightColumn === 'dweight' ? weightDifference : ''}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5} align="center">
                                        <strong>{highestPaymentColumn === 'debit' ? debitCreditDifference : ''}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6} align="center">
                                        <strong>{highestWeightColumn === 'cweight' ? weightDifference : ''}</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7} align="center">
                                        <strong>{highestPaymentColumn === 'credit' ? debitCreditDifference : ''}</strong>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        )}
                    />
                </Spin>
            </div>
        </>
    );
};

export default PurchaseReport;
