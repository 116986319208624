import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Spin,
  Tabs,
  Space,
  Popconfirm,
  Button,
  Input,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { notification } from "antd";
import httpCall from "../../utils/api";
import PageTitle from "../common/PageTitle";
import Imagesettlement from '../../assets/images/settlement.png';

const columnSearch = (searchInput) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder="Search"
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Button
        type="primary"
        onClick={confirm}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[searchInput]
      ? record[searchInput]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : "",
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current.select(), 100);
    }
  },
});

const SettlementList = () => {
  const navigate = useNavigate();
  const searchInput = useRef(null);
  const [salesReceiptList, setSalesReceiptList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1"); // '1' for Customers, '2' for Vendors
  const tableColumnSearch = (dataIndex) => columnSearch(searchInput);

  useEffect(() => {
    setLoading(true);
    if (activeTab === "1") {
      fetchCustomerList();
    } else if (activeTab === "2") {
      fetchVendorList();
    }
  }, [activeTab]);

  const fetchCustomerList = () => {
    setLoading(true); // Set loading state for better UI experience

    httpCall({
      method: "GET",
      url: "/api/settlement/customerSettlementReceiptDetails",
    })
      .then((response) => {
        setLoading(false);

        if (response && response.data) {
          const formattedData = formatSalesReceiptData(response.data);
          setSalesReceiptList(formattedData); // Update the table data
        } else {
          console.error("Error retrieving customer list:", response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error retrieving customer list:", error);
      });
  };

  const fetchVendorList = () => {
    setLoading(true); // Set loading state for better UI experience

    httpCall({
      method: "GET",
      url: "/api/settlement/vendorSettlementReceiptDetails",
    })
      .then((response) => {
        setLoading(false);

        if (response && response.data) {
          const formattedData = formatVendorData(response.data);
          setVendorList(formattedData); // Update the table data
        } else {
          console.error("Error retrieving vendor list:", response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error retrieving vendor list:", error);
      });
  };

  const formatSalesReceiptData = (data) =>
    data.map((item) => ({
      key: item.receipt_no,
      invoice_receipt_no: item.receipt_no,
      invoice_receipt_date: item.receipt_date,
      customer_name: item.customer_name || "Unknown",
      ...item,
    }));

  const formatVendorData = (data) =>
    data.map((item) => ({
      key: item.purchase_no,
      purchase_no: item.purchase_no,
      purchase_date: item.purchase_date,
      vendor_name: item.company_name || "Unknown",
      ...item,
    }));

  const salesReceiptColumns = [
    {
      title: "Invoice Receipt Nr",
      dataIndex: "invoice_receipt_no",
      key: "invoice_receipt_no",
      ...tableColumnSearch("invoice_receipt_no"),
      align: "center",
      width: 150,
      sorter: (a, b) =>
        a.invoice_receipt_no.localeCompare(b.invoice_receipt_no),
      defaultSortOrder: "ascend", // Default sorting in ascending order
    },
    {
      title: "Invoice Receipt Date",
      dataIndex: "invoice_receipt_date",
      key: "invoice_receipt_date",
      align: "center",
      width: 150,
      render: (date) => moment(date).format("DD-MM-YYYY"),
      sorter: (a, b) =>
        a.invoice_receipt_date.localeCompare(b.invoice_receipt_date),
      defaultSortOrder: "ascend", // Default sorting in ascending order
    },
    {
      title: "Customer Name",
      dataIndex: "company_name",
      key: "company_name",
      align: "center",
      width: 200,
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      defaultSortOrder: "ascend", // Default sorting in ascending order
    },
    {
      title: "Total Paid Amount",
      dataIndex: "total_paid_amount",
      key: "total_paid_amount",
      align: "center",
      width: 200,
    },
    {
      title: "Total Pending Amount",
      dataIndex: "pending_amount",
      key: "pending_amount",
      align: "center",
      width: 200,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <EyeOutlined
            style={{ cursor: "pointer", fontSize: "20px", color: "#08c" }}
            onClick={() => navigate(`/settlement/view/${record.key}`)} // Replace with view URL
          />
          <EditOutlined
            style={{ cursor: "pointer", fontSize: "20px", color: "#08c" }}
            onClick={() => navigate(`/settlement/edit/${record.key}`)} // Replace with edit URL
          />
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={""} // Define handleDelete logic
          >
            <DeleteOutlined style={{ fontSize: "20px", color: "#FF6868" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const vendorReceiptColumns = [
    {
      title: "Purchase Payment Nr",
      dataIndex: "purchase_no",
      key: "purchase_no",
      ...tableColumnSearch("purchase_no"),
      align: "center",
      width: 150,
      sorter: (a, b) => a.purchase_no.localeCompare(b.purchase_no),
      defaultSortOrder: "ascend", // Default sorting in ascending order
    },
    {
      title: "Payment Date",
      dataIndex: "purchase_date",
      key: "purchase_date",
      align: "center",
      width: 150,
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.purchase_date) - moment(b.purchase_date),
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      align: "center",
      width: 200,
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      defaultSortOrder: "ascend", // Default sorting in ascending order
    },
    {
      title: "Total Paid Weight",
      dataIndex: "paid_weight",
      key: "paid_weight",
      align: "center",
      width: 200,
    },
    {
      title: "Total Pending Weight",
      dataIndex: "pending_weight",
      key: "pending_weight",
      align: "center",
      width: 200,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <EyeOutlined
            style={{ cursor: "pointer", fontSize: "20px", color: "#08c" }}
            onClick={() => navigate(`/settlement/view/${record.key}`)} // Replace with view URL
          />
          <EditOutlined
            style={{ cursor: "pointer", fontSize: "20px", color: "#08c" }}
            onClick={() => navigate(`/settlement/edit/${record.key}`)} // Replace with edit URL
          />
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={""} // Define handleDelete logic
          >
            <DeleteOutlined style={{ fontSize: "20px", color: "#FF6868" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const renderTable = (dataSource, columns) => (
    <Spin
      tip="Fetching Data From Server, Please Wait ..."
      spinning={loading}
      size="large"
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          position: ["bottomCenter"],
          pageSizeOptions: ["10", "20", "30"],
          showTotal: handleTotal,
        }}
        scroll={{ y: 570 }}
        style={{ fontFamily: "Poppins" }}
      />
    </Spin>
  );

  return (
    <>
      <PageTitle
        imagesrc={Imagesettlement}
        pageTitle={
          activeTab === "1"
            ? "Customer Settlement List"
            : "Vendor Settlement List"
        }
        buttonList={[
          ...(activeTab === "1"
            ? [
                {
                  buttonName: "Create Customer Settlement",
                  className: "btn-create",
                  url: "/slj/settlement/form/customer",
                },
              ]
            : [
                {
                  buttonName: "Create Vendor Settlement",
                  className: "btn-create",
                  url: "/slj/settlement/form/vendor",
                },
              ]),
        ]}
      />
      {contextHolder}
      <div className="vendorListArea">
        <Tabs
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          type="card"
          tabBarGutter={10}
          size="small"
          style={{ fontFamily: "Poppins" }}
          items={[
            {
              key: "1",
              label: "Customers",
              children: renderTable(salesReceiptList, salesReceiptColumns),
            },
            {
              key: "2",
              label: "Vendors",
              children: renderTable(vendorList, vendorReceiptColumns),
            },
          ]}
        />
      </div>
    </>
  );
};

const handleTotal = (total, range) => (
  <div style={{ fontFamily: "Poppins", fontSize: "15px" }}>
    <strong>{range[0].toLocaleString()}</strong> to{" "}
    <strong>{range[1].toLocaleString()}</strong> of{" "}
    <strong>{total.toLocaleString()}</strong> records
  </div>
);

export default SettlementList;
