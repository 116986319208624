import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Select,
  Input,
  Radio,
  DatePicker,
  Button,
  notification,
} from "antd";
import { useFormik } from "formik";
import PageTitle from "../common/PageTitle";
import Imagesettlement from '../../assets/images/settlement.png';
import httpCall from "../../utils/api";
import moment from "moment";

const SettlementCustomerCreate = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [settlementList, setSettlementList] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const formik = useFormik({
    initialValues: {
      receipt_no: "",
      receipt_no_inc: "",
      receipt_date: moment().format("YYYY-MM-DD"),
      customer_id: "",
      payment_mode_1: "Cash",
      account_1: "",
      paid_amount_1: 0,
      payment_mode_2: "Cash",
      account_2: "",
      paid_amount_2: 0,
      pending_amount: "",
      total_paid_amount: 0,
    },
    onSubmit: async (values) => {
      const paidAmount1 = Number(values.paid_amount_1 || 0);
      const paidAmount2 = Number(values.paid_amount_2 || 0);
      if (!values.customer_id || (paidAmount1 <= 0 && paidAmount2 <= 0)) {
        api.error({
          message: "Validation Error",
          description: "Please enter required fields.",
        });
        return;
      }

      try {
        await createSalesRecipt(values);
      } catch (error) {
        console.log("Failed to create invoice receipt");
      }
    },
  });

  const createSalesRecipt = async (values) => {
    try {
      const response = await httpCall({
        method: "POST",
        url: "/api/settlement/customerSettlementReceipt",
        payload: values,
      });

      if (response) {
        navigate("/slj/settlementlist");
      } else {
        console.log("Error while creating invoice receipt");
      }
    } catch (error) {
      console.error("Error creating invoice receipt:", error);
    }
  };

  const fetchPaymentOptions = async () => {
    try {
      setLoading(true);
      const response = await httpCall({
        method: "GET",
        url: "/api/payment/list?filter=true",
      });
      if (response) {
        setPaymentOptions(response?.data || []);
      } else {
        console.error("Error retrieving payment list:", response.message);
      }
    } catch (error) {
      console.error("Error retrieving payment list:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomerList = async () => {
    try {
      const response = await httpCall({
        method: "GET",
        url: "/api/customer/get",
      });
      if (response) {
        setSettlementList(response?.data || []);
      } else {
        console.error("Error retrieving customer list:", response.message);
      }
    } catch (error) {
      console.error("Error retrieving customer list:", error);
    }
  };

  const fetchNextCustomerSettlementNo = async () => {
    try {
      const response = await httpCall({
        method: "GET",
        url: "/api/settlement/fetchNextCustomerSettlementNo",
      });
      if (response) {
        formik.setFieldValue(
          "receipt_no",
          `SC${response.data}/${moment().format("DDMMYYYY")}`
        );
        formik.setFieldValue("receipt_no_inc", response.data);
      } else {
        console.error(
          "Error retrieving settlement receipt no:",
          response.message
        );
      }
    } catch (error) {
      console.error("Error retrieving settlement receipt no:", error);
    }
  };

  const fetchAccountList = async () => {
    try {
      setLoading(true);
      const response = await httpCall({
        method: "GET",
        url: "/api/account/list",
      });
      if (response) {
        setAccountList(response?.data || []);
      } else {
        console.error("Error retrieving account list:", response.message);
      }
    } catch (error) {
      console.error("Error retrieving account list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const totalPaid =
      Number(formik.values.paid_amount_1 || 0) +
      Number(formik.values.paid_amount_2 || 0);
    formik.setFieldValue("total_paid_amount", totalPaid);
    const totalPending =
      formik.values.opening_balance - totalPaid > 0
        ? formik.values.opening_balance - totalPaid
        : 0;
    formik.setFieldValue("pending_amount", totalPending);
  }, [formik.values.paid_amount_1, formik.values.paid_amount_2]);

  useEffect(() => {
    fetchCustomerList();
    fetchPaymentOptions();
    fetchAccountList();
    fetchNextCustomerSettlementNo(); // Fetch settlement number on component load
  }, []);

  return (
    <>
      <PageTitle
        imagesrc={Imagesettlement}
        pageTitle="Create Customer Settlement"
        buttonList={[
          {
            buttonName: "Back",
            className: "btn-back",
            url: "/slj/settlementlist",
          },
        ]}
      />
      {contextHolder}
      <div className="content-area">
        <div className="formAreaStyle">
          <form onSubmit={formik.handleSubmit}>
            <div className="customer-settlement-form">
              {/* Receipt No Row */}
              <Row gutter={16} justify="end">
                <Col span={5}>
                  <div>
                    <span className="hintText color-black">Receipt No</span>
                  </div>
                  <Input
                    id="receipt_no"
                    disabled
                    style={{
                      width: "100%",
                      color: "#000",
                      fontSize: 14,
                    }}
                    value={formik.values.receipt_no}
                  />
                </Col>
              </Row>

              {/* Customer and Receipt Date */}
              <Row gutter={16}>
                <Col span={8}>
                  <span className="hintText color-black">Customer</span>
                  <span className="mandatory"></span>
                  <Select
                    id="customer_id"
                    placeholder="Select Customer"
                    style={{ width: "100%", height: 38, textAlign: "left" }}
                    options={settlementList.map((customer) => ({
                      label: customer.company_name,
                      value: customer.id,
                    }))}
                    value={formik.values.customer_id}
                    onChange={(value) => {
                      const selectedCustomer = settlementList.find(
                        (customer) => customer.id === value
                      );
                      formik.setFieldValue("customer_id", value);
                      formik.setFieldValue(
                        "opening_balance",
                        selectedCustomer?.pending_amount || 0
                      );
                    }}
                  />
                </Col>
                <Col span={8}>
                  <span className="hintText color-black">
                    Total Opening Balance
                  </span>
                  <Input
                    disabled
                    id="pending_amount"
                    style={{
                      width: "100%",
                      color: "green",
                      fontSize: 18,
                      height: 38,
                      textAlign: "left",
                    }}
                    value={formik.values.opening_balance}
                  />
                </Col>
                <Col span={8}>
                  <span className="hintText color-black">Receipt Date</span>
                  <DatePicker
                    style={{ width: "100%", textAlign: "left" }}
                    value={
                      formik.values.receipt_date
                        ? moment(formik.values.receipt_date)
                        : moment()
                    }
                    onChange={(date) =>
                      formik.setFieldValue("receipt_date", date)
                    }
                    disabled
                  />
                </Col>
              </Row>

              {/* Payment Mode 1 */}
              <Row gutter={16}>
                <Col span={8}>
                  <span
                    className="hintText color-black"
                    style={{ fontWeight: "600" }}
                  >
                    PAYMENT MODE 1
                  </span>
                  <Radio.Group
                    id="payment_mode_1"
                    value={formik.values.payment_mode_1}
                    onChange={(e) =>
                      formik.setFieldValue("payment_mode_1", e.target.value)
                    }
                    style={{
                      marginTop: 5,
                      fontSize: 16,
                      textAlign: "left",
                    }}
                  >
                    {paymentOptions.map((option, index) => (
                      <Radio key={index} value={option.mop}>
                        {option.mop}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Col>
                <Col span={8}>
                  <span className="hintText color-black">Bank</span>
                  <Select
                    id="account_1"
                    disabled={formik.values.payment_mode_1 === "Cash"}
                    placeholder="Select an Account"
                    style={{ width: "100%", height: 38, textAlign: "left" }}
                    options={accountList.map((acc) => ({
                      label: `${acc.bank_name} - ${acc.account_no}`,
                      value: acc.account_no,
                    }))}
                    value={formik.values.account_1}
                    onChange={(value) =>
                      formik.setFieldValue("account_1", value)
                    }
                  />
                </Col>
                <Col span={8}>
                  <span className="hintText color-black">Payment 1</span>
                  <span className="mandatory"></span>
                  <Input
                    id="paid_amount_1"
                    name="paid_amount_1"
                    type="text"
                    prefix="₹"
                    placeholder="0.00"
                    style={{ width: "100%", height: 38, textAlign: "left" }}
                    // value={formik.values.paid_amount_1}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              {/* Payment Mode 2 */}
              <Row gutter={16}>
                <Col span={8}>
                  <span
                    className="hintText color-black"
                    style={{ fontWeight: "600" }}
                  >
                    PAYMENT MODE 2
                  </span>
                  <Radio.Group
                    id="payment_mode_2"
                    value={formik.values.payment_mode_2}
                    onChange={(e) =>
                      formik.setFieldValue("payment_mode_2", e.target.value)
                    }
                    style={{
                      marginTop: 5,
                      fontSize: 16,
                      textAlign: "left",
                    }}
                  >
                    {paymentOptions.map((option, index) => (
                      <Radio key={index} value={option.mop}>
                        {option.mop}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Col>
                <Col span={8}>
                  <span className="hintText color-black">Bank</span>
                  <Select
                    id="account_2"
                    disabled={formik.values.payment_mode_2 === "Cash"}
                    placeholder="Select an Account"
                    style={{ width: "100%", height: 38, textAlign: "left" }}
                    options={accountList.map((acc) => ({
                      label: `${acc.bank_name} - ${acc.account_no}`,
                      value: acc.account_no,
                    }))}
                    value={formik.values.account_2}
                    onChange={(value) =>
                      formik.setFieldValue("account_2", value)
                    }
                  />
                </Col>
                <Col span={8}>
                  <span className="hintText color-black">Payment 2</span>
                  <Input
                    id="paid_amount_2"
                    name="paid_amount_2"
                    type="text"
                    prefix="₹"
                    placeholder="0.00"
                    style={{ width: "100%", height: 38, textAlign: "left" }}
                    // value={formik.values.paid_amount_2}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>

              {/* Total Paid Amount */}
              <Row gutter={16} justify="end">
                <Col span={6}>
                  <span className="hintText color-black">
                    Total Paid Amount
                  </span>
                  <Input
                    disabled
                    id="total_paid_amount"
                    style={{ textAlign: "left" }}
                    value={formik.values.total_paid_amount}
                  />
                </Col>
              </Row>

              {/* Action Buttons */}
              <Row gutter={16} justify="end" style={{ marginTop: "5%" }}>
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    style={{ marginRight: 8, backgroundColor: "grey" }}
                    onClick={() => formik.resetForm()}
                  >
                    Reset Fields
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{ backgroundColor: "#1f43e5" }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SettlementCustomerCreate;
