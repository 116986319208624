import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Row, Spin, Table, notification,Select} from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/sales.png';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { useFormik } from 'formik';
import moment from 'moment';

const { RangePicker } = DatePicker;

const DayBookReport = () => {
    const navigate = useNavigate();
    const [reportData, setReportData] = useState([]);
    const [saleReportData, setSaleReportData] = useState([]);
    const [saleReceiptReportData, setSaleReceiptReportData] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [costCenterData, setCostCenterData] = useState([]);
    
    const formik = useFormik({
        initialValues: {
            from_date: moment().format('YYYY-MM-DD'),
            to_date: moment().format('YYYY-MM-DD'),
            cost_center_id: ''
        },
        onSubmit: (values, { resetForm }) => {}
    });

    useEffect(() => {
        const { from_date, to_date, cost_center_id } = formik.values;
        setLoading(true);
        fetchSaleList(from_date, to_date, cost_center_id);
        fetchSaleReceiptList(from_date, to_date, cost_center_id);
        fetchMaterialReturnList(from_date, to_date, cost_center_id);
        fetchCostCenters();
    }, []);

    const fetchMaterialReturnList = (from, to, id) => {
        httpCall({
            method: 'GET',
            url: `/api/sales/material-return/report?from=${from}&to=${to}&cost_center_id=${id || ''}`
        })
        .then((response) => {
            setLoading(false);
            setReportData(response?.data || []);
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error retrieving sales order data:', error);
        });
    };

    const fetchSaleList = (from, to, id) => {
        httpCall({
            method: 'GET',
            url: `/api/sales/sale/report?from=${from}&to=${to}&cost_center_id=${id || ''}`
        })
        .then((response) => {
            setLoading(false);
            setSaleReportData(response?.data || []);
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error retrieving sales order data:', error);
        });
    };

    const fetchSaleReceiptList = (from, to, id) => {
        httpCall({
            method: 'GET',
            url: `/api/sales/sale-receipt/report?from=${from}&to=${to}&cost_center_id=${id || ''}`
        })
        .then((response) => {
            setLoading(false);
            setSaleReceiptReportData(response?.data || []);
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error retrieving sales order data:', error);
        });
    };

    const fetchCostCenters = async () => {
        try {
            const response = await httpCall({
                method: 'GET',
                url: '/api/costcenter/get',
            });
            if (response && response.data) {
                setCostCenterData(response?.data.map(cc => ({label: cc.cost_center_name, value: cc.cost_center_id})));
            } else {
                setCostCenterData([]);
                console.error('Error retrieving cost center data: Unexpected response format', response);
            }
        } catch (error) {
            setCostCenterData([]);
            console.error('Error fetching cost center data:', error);
        }
    };

    // Safe comparison function for sorting
    const safeCompare = (a, b) => {
        if (a === undefined && b === undefined) return 0;
        if (a === undefined) return -1;
        if (b === undefined) return 1;
        return a.toString().localeCompare(b.toString());
    };
    const handleDateChange = (dates) => {
        if (dates) {
            const start = dates[0].format('YYYY-MM-DD');
            const end = dates[1].format('YYYY-MM-DD');
            formik.setFieldValue('from_date', start);
            formik.setFieldValue('to_date', end);
            fetchSaleList(start, end, formik.values.cost_center_id);
            fetchSaleReceiptList(start, end, formik.values.cost_center_id);
            fetchMaterialReturnList(start, end, formik.values.cost_center_id);
        }
    };

    const handleCostCenterChange = (id) => {
        formik.setFieldValue('cost_center_id', id);
        fetchSaleList(formik.values.from_date, formik.values.to_date, id);
        fetchSaleReceiptList(formik.values.from_date, formik.values.to_date, id);
        fetchMaterialReturnList(formik.values.from_date, formik.values.to_date, id);
    };

    const saleReportColumns = [
        {
            title: 'Item Name',
            dataIndex: 'itemname',
            key: 'itemname',
            align: 'center',
            width: 150,
            sorter: (a, b) => safeCompare(a.itemname, b.itemname),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Bill Nr',
            dataIndex: 'invoice_no',
            key: 'invoice_no',
            align: 'center',
            width: 125,
            sorter: (a, b) => safeCompare(a.invoice_no, b.invoice_no),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            align: 'center',
            width: 100,
            sorter: (a, b) => {
                const dateA = a.date ? moment(a.date) : null;
                const dateB = b.date ? moment(b.date) : null;
                if (!dateA && !dateB) return 0;
                if (!dateA) return -1;
                if (!dateB) return 1;
                return dateA - dateB;
            },
            defaultSortOrder: 'ascend',
            render: (_, record) => (record.date ? moment(record.date).format('DD-MM-YYYY') : '')
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            align: 'center',
            width: 75
        }
    ];

    const saleReceiptReportColumns = [
        {
            title: 'Receipt Nr',
            dataIndex: 'invoice_receipt_no',
            key: 'invoice_receipt_no',
            align: 'center',
            width: 125,
            sorter: (a, b) => safeCompare(a.invoice_receipt_no, b.invoice_receipt_no),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Bill Nr',
            dataIndex: 'invoice_no',
            key: 'invoice_no',
            align: 'center',
            width: 125,
            sorter: (a, b) => safeCompare(a.invoice_no, b.invoice_no),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Receipt Date',
            dataIndex: 'invoice_receipt_date',
            key: 'invoice_receipt_date',
            align: 'center',
            width: 100,
            sorter: (a, b) => {
                const dateA = a.date ? moment(a.date) : null;
                const dateB = b.date ? moment(b.date) : null;
                if (!dateA && !dateB) return 0;
                if (!dateA) return -1;
                if (!dateB) return 1;
                return dateA - dateB;
            },
            defaultSortOrder: 'ascend',
            render: (_, record) => (record.date ? moment(record.date).format('DD-MM-YYYY') : '')
        },
        {
            title: 'Cash Payment',
            dataIndex: 'paid_amount_1',
            key: 'paid_amount_1',
            align: 'center',
            width: 75,
            render: (_, record) => {
              const isPayment1Cash = record.payment_mode_1?.toLowerCase() === 'cash';
              const isPayment2Cash = record.payment_mode_2?.toLowerCase() === 'cash';
              const cashPayment = isPayment1Cash ? record.paid_amount_1 : (isPayment2Cash ? record.paid_amount_2 : '');
              
              return cashPayment
                ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cashPayment)
                : '';
            },
          },          
          {
            title: 'Bank/Card Payment',
            dataIndex: 'paid_amount_2',
            key: 'paid_amount_2',
            align: 'center',
            width: 75,
            render: (_, record) => {
              const isPayment1Card = record.payment_mode_1?.toLowerCase() !== 'cash';
              const isPayment2Card = record.payment_mode_2?.toLowerCase() !== 'cash';
          
              let cardPayment = 0;
              if (isPayment1Card) {
                cardPayment += record.paid_amount_1 || 0;
              }
              if (isPayment2Card) {
                cardPayment += record.paid_amount_2 || 0;
              }
          
              return cardPayment > 0
                ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cardPayment)
                : '';
            },
          },                 
    ];

    const columnsNew = [
        {
            title: 'Item Name',
            dataIndex: 'itemname',
            key: 'itemname',
            align: 'center',
            width: 150,
            sorter: (a, b) => safeCompare(a.itemname, b.itemname),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Bill Nr',
            dataIndex: 'transaction_no',
            key: 'transaction_no',
            align: 'center',
            width: 125,
            sorter: (a, b) => safeCompare(a.transaction_no, b.transaction_no),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Date',
            dataIndex: 'transaction_date',
            key: 'transaction_date',
            align: 'center',
            width: 100,
            sorter: (a, b) => {
                const dateA = a.transaction_date ? moment(a.transaction_date) : null;
                const dateB = b.transaction_date ? moment(b.transaction_date) : null;
                if (!dateA && !dateB) return 0;
                if (!dateA) return -1;
                if (!dateB) return 1;
                return dateA - dateB;
            },
            defaultSortOrder: 'ascend',
            render: (_, record) => (record.transaction_date ? moment(record.transaction_date).format('DD-MM-YYYY') : '')
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            align: 'center',
            width: 75
        }
    ];

    const mergedColumns = React.useMemo(() => {
        return columnsNew.map((col) => ({
            ...col,
            onCell: undefined
        }));
    }, []);

    const footerTotalAmount = (list, label, unit) => {
        const total = list.reduce((acc, record) => {
          const payment1 = Number(record.paid_amount_1 || 0);
          const payment2 = Number(record.paid_amount_2 || 0);
          return acc + payment1 + payment2;
        }, 0);
      
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <div style={{ width: '20%', textAlign: 'center', backgroundColor: '#FFFFFF' }}>
              <span>{label}: </span>
              <span style={{ fontSize: '18px', fontWeight: '600' }}>
                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total)} {unit}
              </span>
            </div>
          </div>
        );
      };
      const footerTotalWeight = (list, label, field, unit) => {
        const total = list.reduce((acc, next) => {
          // Convert to number, handling potential undefined or non-numeric values
          const value = Number(next[field] || 0);
          return isNaN(value) ? acc : acc + value;
        }, 0);
      
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <div style={{ width: '20%', textAlign: 'center', backgroundColor: '#FFFFFF' }}>
              <span>{label}: </span>
              <span style={{ fontSize: '18px', fontWeight: '600' }}>
                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total)} {unit}
              </span>
            </div>
          </div>
        );
      };
            
return (
  <>
        <PageTitle imagesrc={SalesImage} pageTitle="Day Book Report" buttonList={[]} />
    {contextHolder}
    <div className="vendorListArea">
      <Row gutter={12} className="gutter-row" style={{ marginBottom: "30px" }}>
        <Col span={6} className="gutter-box">
          <div>
            <span className="hintText color-black">Select Date Range</span>
          </div>
          <RangePicker style={{ height: "38px" }} onChange={handleDateChange} />
        </Col>
        <Col span={6} className="gutter-box">
          <div>
            <span className="hintText color-black">Managed By</span>
          </div>
          <Select
            id="cost_center_id"
            showSearch
            placeholder="Select Manager"
            style={{ width: "100%", height: "38px" }}
            options={costCenterData}
            onChange={handleCostCenterChange}
            allowClear
          />
        </Col>
      </Row>
            <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '50px' }}>
          <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Gold Sale</span>
            <Table
              dataSource={saleReportData.filter(
                (r) => r.item_type && r.item_type.toLowerCase() === "gold"
              )}
              size="small"
              bordered
              columns={saleReportColumns}
              rowKey="invoice_no"
                            scroll={{ y: '250px' }}
              pagination={false}
              footer={() =>
                footerTotalWeight(
                  saleReportData.filter(
                    (r) => r.item_type && r.item_type.toLowerCase() === "gold"
                  ),
                  "Total Weight",
                  "weight",
                  "g"
                )
              }
                            style={{ fontFamily: 'Poppins', marginTop: '10px' }}
            />
          </Col>
        </Row>

                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '50px' }}>
          <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Silver Sale</span>
            <Table
                            dataSource={saleReportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'silver')}
              size="small"
              bordered
              columns={saleReportColumns}
              rowKey="invoice_no"
                            scroll={{ y: '250px' }}
              pagination={false}
              footer={() =>
                footerTotalWeight(
                  saleReportData.filter(
                    (r) => r.item_type && r.item_type.toLowerCase() === "silver"
                  ),
                  "Total Weight",
                  "weight",
                  "g"
                )
              }
                            style={{ fontFamily: 'Poppins', marginTop: '10px' }}
            />
          </Col>
        </Row>

                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '50px' }}>
          <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Sales Receipt</span>
            <Table
              dataSource={saleReceiptReportData}
              size="small"
              bordered
              columns={saleReceiptReportColumns}
              rowKey="invoice_receipt_no"
                            scroll={{ y: '250px' }}
              pagination={false}
              footer={() =>
                footerTotalAmount(saleReceiptReportData, "Total Amount", "₹")
              }
              style={{ fontFamily: "Poppins", marginTop: "10px" }}
            />
          </Col>
        </Row>

                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '30px' }}>
          <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Old Gold Purchase</span>
            <Table
              dataSource={reportData.filter(
                (r) => r.item_type && r.item_type.toLowerCase() === "gold"
              )}
              size="small"
              bordered
              columns={mergedColumns}
              rowKey="return_item_no"
                            scroll={{ y: '250px' }}
              pagination={false}
              footer={() =>
                footerTotalWeight(
                  reportData.filter(
                    (r) => r.item_type && r.item_type.toLowerCase() === "gold"
                  ),
                  "Total Weight",
                  "weight",
                  "g"
                )
              }
                            style={{ fontFamily: 'Poppins', marginTop: '10px' }}
            />
          </Col>
        </Row>

                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '30px' }}>
          <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Old Silver Purchase</span>
            <Table
                            dataSource={reportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'silver')}
              size="small"
              bordered
              columns={mergedColumns}
              rowKey="return_item_no"
                            scroll={{ y: '250px' }}
              pagination={false}
              footer={() =>
                footerTotalWeight(
                  reportData.filter(
                    (r) => r.item_type && r.item_type.toLowerCase() === "silver"
                  ),
                  "Total Weight",
                  "weight",
                  "g"
                )
              }
                            style={{ fontFamily: 'Poppins', marginTop: '10px' }}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  </>
);
};

export default DayBookReport;
