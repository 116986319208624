import React, { useEffect, useState } from 'react';
import { Badge, Button, Checkbox, Col, DatePicker, Divider, Flex, Form, Input, InputNumber, Popconfirm, Radio, Row, Select, Space, Steps, Switch, Table, Tag, Typography, notification } from 'antd';
import { CheckCircleFilled, EditOutlined, ProfileOutlined, ProfileTwoTone, SolutionOutlined, UserOutlined, WarningFilled } from '@ant-design/icons';
import VendorImage from '../../assets/images/vendor_payment.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import EditableCell from '../common/EditableCell';

const PurchasePayment = () => {
    const [vendorNameOptions, setVendorNameOptions] = useState([]);    
	const [api, contextHolder] = notification.useNotification();
    const [current, setCurrent] = useState(0);
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);    
    const [selectedPurchaseOrderList, setSelectedPurchaseOrderList] = useState([]);    
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activatePM2, setActivatePM2] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); // New state to track selected rows
    const navigate = useNavigate();
    const [vendorList,setVendorList]=useState([])
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
	const isEditing = (record) => record.purchase_order_no === editingKey;

    const steps = [
        {
          title: 'Select Vendor',
          content: 'First-content',
          icon: <UserOutlined />
        },
        {
          title: 'Select Bills To Pay',
          content: 'Second-content',
          icon: <SolutionOutlined />
        },
        {
          title: 'View Payment Summary',
          content: 'Last-content',
        },
    ];

    const prev = () => {
        if (current === 1) {
            // Reset paid amounts and clear selected checkboxes when moving from Step 2 to Step 1
            purchaseOrderList.forEach(po => {
                po.paid_weight = 0; // Reset paid weight
                po.pending_weight = po.po_weight; // Reset pending weight
            });
            setPurchaseOrderList([...purchaseOrderList]); // Update the state with the reset list
            setSelectedPurchaseOrderList([]); // Clear selected checkboxes
        }
        setCurrent(current - 1); // Navigate to the previous step
    };
    

    const next = () => {
        setCurrent(current + 1);
    };

    const edit = function(record){        
		form.setFieldsValue({
            paid_weight: 0,
			...record,
		});
		setEditingKey(record.purchase_order_no);
	};

	const cancel = () => {
		setEditingKey('');
	};

    const totalPendingWeightPerVendor = (totalPendingPayments) => {
        return totalPendingPayments.reduce((acc, next) => acc + parseFloat(next.po_weight || 0), 0).toFixed(3);
    }

    const totalWeightPaidForSelectedBills = () => {
        return purchaseOrderList.reduce((acc, next) => acc + parseFloat(next.paid_weight || 0), 0);
    }

    const totalWeightInCustomerWallet = () => {
        return parseFloat(formik.values.calc_weight || 0) + parseFloat(formik.values.customer_advance_weight || 0);
    }

    const update = async (key) => {
		try {
			const row = await form.validateFields();
            const updated_paid_weight = parseFloat(row.paid_weight || 0);
            const poToUpdate = purchaseOrderList.find(po => po.purchase_order_no === key);            
            if(poToUpdate.po_weight < updated_paid_weight) {
                openErrorNotification('Paid Weight Per Bill Exceeds Bill Weight');
                cancel();
                return;
            }
            let paidTotal = totalWeightPaidForSelectedBills();
            paidTotal = paidTotal - poToUpdate.paid_weight + updated_paid_weight;            

            if(totalWeightInCustomerWallet() < paidTotal) {
                openErrorNotification('Total Paid Weight Exceeds Customer Payment');
                cancel();
                return;
            }
            purchaseOrderList.forEach(po => {
                if(po.purchase_order_no === key) {                           
                    po.paid_weight = row.paid_weight;
                    po.pending_weight = po.po_weight - row.paid_weight;
                }
            });
            setPurchaseOrderList([...purchaseOrderList]);
            cancel();
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
        icon: item.icon,
    }));

    const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

  const formik = useFormik({
    initialValues: {
      purchase_receipt_no: '1',
      payment_mode_1: 'Cash',
      payment_mode_2: 'Banking',
      account_1: '',
      account_2: '',
      vendor_id: '',
      vendor_name: '',        
      purchase_receipt_date: moment().format('YYYY-MM-DD'),
      amount_1_received: '',
      amount_2_received: '',
      amount_received: '',
      rate_per_gram: '',
      vendor_paid_weight: '',
      purity: '', 
      old_gold_weight: '',
      calc_weight: '',            
      total_receipt_weight: '',
      total_pending_weight: '',
      customer_advance_weight: 0,
      closing_weight: 0.00, 
      opening_weight: 0.00       
    },
    onSubmit: (values, { resetForm }) => {
      values['purchaseOrderList'] = selectedPurchaseOrderList;            
      createPurchasePayment(values);
    },
  });
  
  useEffect(() => {
    formik.setFieldValue('total_receipt_weight', totalWeightPaidForSelectedBills());
  }, [purchaseOrderList]);
  
  useEffect(() => {
    // Sync selectedRowKeys with purchaseOrderList when revisiting Step 2
    if (current === 1) {
      const keys = purchaseOrderList
        .filter((po) => po.paid_weight > 0)
        .map((po) => po.purchase_order_no);
      setSelectedRowKeys(keys);
    }
  }, [current, purchaseOrderList]);
  
  const closingBalanaceWeight = () => {
    const weight = parseFloat(parseFloat(formik.values.opening_weight || 0) - parseFloat(formik.values.calc_weight || 0)).toFixed(3);
    return weight < 0 ? 0 : weight;
  };
  
  useEffect(() => {
    const closingBalanaceWeight1 = closingBalanaceWeight();
    formik.setFieldValue('closing_weight', closingBalanaceWeight1)
  }, [current === 2]);
  
  useEffect(() => {
    if (formik.values.rate_per_gram !== '') {
      const rate_per_gram = parseFloat(formik.values.rate_per_gram || 1);
      const amount_1_received = parseFloat(formik.values.amount_1_received || 0);
      const amount_2_received = parseFloat(formik.values.amount_2_received || 0);
      const old_gold_weight = parseFloat(formik.values.old_gold_weight || 0);
      const amount = amount_1_received + amount_2_received;
      formik.setFieldValue('amount_received', amount_1_received.toFixed(3));
  
      const calc_weight = (amount / rate_per_gram);
      formik.setFieldValue('calc_weight', (calc_weight + old_gold_weight).toFixed(3));
    } else if (formik.values.old_gold_weight !== '') {
      formik.setFieldValue('calc_weight', parseFloat(formik.values.old_gold_weight || 0).toFixed(3));
    }
  }, [
    formik.values.rate_per_gram,
    formik.values.amount_1_received,
    formik.values.amount_2_received,
    formik.values.old_gold_weight
  ]);
  
  useEffect(() => {
    if (formik.values.vendor_paid_weight !== '' && formik.values.purity !== '') {
      const vendor_paid_weight = parseFloat(formik.values.vendor_paid_weight || 0);
      const purity = parseFloat(formik.values.purity || 0);
      const old_gold_weight = vendor_paid_weight * (purity / 100);
      formik.setFieldValue('old_gold_weight', old_gold_weight.toFixed(3));
    }
  }, [formik.values.vendor_paid_weight, formik.values.purity]);
  
  useEffect(() => {
    fetchPaymentOptions();
    fetchNextPaymentNo(); 
    fetchVendorList();
    fetchAccountList();      
  }, []);
  
    useEffect(() => {
        if(formik.values.vendor_id) {
            fetchPurchaseOrdersByVendor(formik.values.vendor_id);
        }        
    }, [formik.values.vendor_id]);

    const fetchAccountList = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/account/list',
		})
			.then((response) => {
				if (response) {
					setAccountList(response?.data.map(acc => ({label: `${acc.bank_name} - ${acc.account_no}`, value: acc.account_no})));
				} else {
					console.error(
						'Error retrieving account list',
						response.message
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving account list', error);
				setLoading(false);
			});
	};

    const openSuccessNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const fetchPaymentOptions = () => {
		httpCall({
			method: 'GET',
			url: '/api/payment/list?filter=true',
		})
			.then((response) => {
                setPaymentOptions(response?.data);
			})
			.catch((error) => {
				console.error('Error retrieving payment list:', error);
			});
	};

    const fetchNextPaymentNo = () => {
		httpCall({
			method: 'GET',
			url: '/api/purchase/getPurchasePaymentNr',
		})
			.then((response) => {
				if (response) {
                    formik.setFieldValue('purchase_receipt_no', response.data);
				} else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

    const fetchVendorList = () => {
		httpCall({
			method: 'GET',
			url: '/api/vendor/get',
		})
			.then((response) => {
				if (response) {
                    setVendorList(response.data);
                    setVendorNameOptions(response?.data.map(c => ({label: c.company_name, value: c.id})))
				} else {
					console.error(
						'Error retrieving vendor list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving vendor list:', error);
			});
	};

    const createPurchasePayment = (values) => {
		httpCall({
			method: 'POST',
			url: '/api/purchase/purchaseReceipt',
            payload: values
		})
			.then((response) => {
				if (response) {
                    openSuccessNotification('Purchase Payment Created Successfully !')
                    navigate("/slj/purchase-receipt");
				} else {
					console.error(
						'Error creating purchase payment:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error creating purchase payment:', error);
			});
	};

    const fetchPurchaseOrdersByVendor = (id) => {
		httpCall({
			method: 'GET',
			url: '/api/purchase/getPurchaseOrderInfo/' + id,
		})
			.then((response) => {
                if(response.status === 204) {
                    formik.setFieldValue('total_pending_weight', 'No Pending Weight :)');
                } else {
                    const po = response?.data;
                    if(po?.pendingWeights?.length === 0) {
                        formik.setFieldValue('total_pending_weight', 'No Pending Weight :)');
                    } else {
                        const list = po?.pendingWeights.map(po =>({purchase_order_no: po.purchase_order_no, po_weight: po.pending_weight, paid_weight: 0, pending_weight: po.pending_weight}));
                        setPurchaseOrderList([...list]);
                        formik.setFieldValue('total_pending_weight', totalPendingWeightPerVendor([...list]));
                    }                   
                }
			})
			.catch((error) => {
				openErrorNotification(`Error retrieving recepits per vendor`);
			});
	};

    const purchasePaymentColumns = [
        {
            title: 'Purchase Nr',
            dataIndex: 'purchase_order_no',
            key: 'purchase_order_no',
            align: 'center',
            width: 150
        },
        {
            title: 'Purchase Weight',
            dataIndex: 'po_weight',
            key: 'po_weight',       
            align: 'center',
            width: 150
        },
        {
            title: 'Paid Weight',
            dataIndex: 'paid_weight',
            key: 'paid_weight',
            align: 'center',
            editable: true,
            width: 150,
            render: (value) => {
                const numValue = parseFloat(value);
                return isNaN(numValue) ? '' : numValue.toFixed(2); // Safe check
            }
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 150,
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => update(record.purchase_order_no)}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <EditOutlined
                        style={{
                            cursor: 'pointer',
                            fontSize: '20px',
                            color: '#08c',
                        }}
                        onClick={() => edit(record)}
                    />
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',      
            align: 'center',
            width: 150,
            render: (_, record) => {
                return record.po_weight > record.paid_weight ? (
                    <Tag color='#f50'>PENDING</Tag>
                ) : (<Tag color="#87d068">FULLY PAID</Tag>);
            }
        },
    ];
    
    const step1 = () => {
        return (
          <>
            <Row gutter={16} justify="center" className="gutter-row">
              <Col span={24} className="gutter-box">
                <div style={{ textAlign: "right" }}>
                  <span className="hintText color-black">Payment No</span>
                </div>
                <Input
                  type="text"
                  id="purchase_receipt_no"
                  disabled={true}
                  variant="borderless"
                  style={{
                    width: "100%",
                    textAlign: "right",
                    color: "#000000",
                    paddingLeft: "0px",
                    fontSize: "14px",
                  }}
                  value={formik.values.purchase_receipt_no}
                />
              </Col>
            </Row>
            <Row gutter={16} justify="center" className="gutter-row">
              <Col span={8} className="gutter-box">
                <div>
                  <span className="hintText color-black">Vendor</span>
                </div>
                <Select
                  id="vendor_id"
                  placeholder="Select a Vendor"
                  style={{ width: "100%", height: "38px" }}
                  options={vendorNameOptions}
                  value={formik.values.vendor_name}
                  onChange={(e) => {
                    formik.setFieldValue("vendor_id", e);
                    const vendor = vendorList.find((v) => v.id === e); // Match vendor by ID
                    if (vendor) {
                      formik.setFieldValue("vendor_name", vendor.company_name); // Update vendor_name
                      formik.setFieldValue(
                        "opening_weight",
                        vendor.pending_weight
                      ); // Update pending_weight
                    }
                  }}
                />
              </Col>
              <Col span={8} className="gutter-box">
                <div>
                  <span className="hintText color-black">
                    Total Pending Weight (g)
                  </span>
                </div>
                <Input
                  disabled={true}
                  type="text"
                  id="total_pending_weight"
                  style={{
                    width: "100%",
                    color: "green",
                    fontSize: "18px",
                    height: "38px",
                  }}
                  value={formik.values['opening_weight']}
                />
              </Col>
              <Col span={8} className="gutter-box">
                <div>
                  <span className="hintText color-black">Payment Date</span>
                </div>
                <DatePicker
                  style={{ width: "100%", height: "38px" }}
                  format={{
                    format: "YYYY-MM-DD",
                    type: "mask",
                  }}
                  disabled={true}
                  defaultValue={moment()}
                  onChange={(date, dateString) => {
                    formik.setFieldValue("purchase_receipt_date", dateString);
                  }}
                />
              </Col>
            </Row>
            <Row gutter={16} justify="end" className="gutter-row">
              <Col span={8} className="gutter-box">
                <div>
                  <span className="hintText color-black">Rate per gram</span>
                </div>
                <Input
                  type="text"
                  prefix="₹"
                  placeholder="0.00"
                  id="rate_per_gram"
                  style={{ width: "100%", height: "38px" }}
                  value={formik.values["rate_per_gram"]}
                  onChange={(e) => {
                    const value = e.target.value;
                    formik.setFieldValue("rate_per_gram", value);
                    if (formik.values.amount_1_received) {
                      const amount =
                        parseFloat(formik.values.amount_1_received || 0) +
                        parseFloat(formik.values.amount_2_received || 1);
                      const calc_weight = amount / parseFloat(value);
                      formik.setFieldValue(
                        "calc_weight",
                        calc_weight.toFixed(3)
                      );
                    }
                  }}
                />
              </Col>
            </Row>
            <Row gutter={16} justify="end" className="gutter-row">
              <Col span={8} className="gutter-box">
                <div>
                  <span
                    className="hintText color-black"
                    style={{ fontWeight: "600" }}
                  >
                    PAYMENT MODE 1
                  </span>
                </div>
                <Radio.Group
                  style={{ marginTop: "5px", fontSize: " 16px" }}
                  onChange={(e) => {
                    formik.setFieldValue("payment_mode_1", e.target.value);
                  }}
                  value={formik.values.payment_mode_1}
                >
                  {paymentOptions.map((option, index) => (
                    <Radio
                      key={index}
                      style={{ fontSize: "13px", fontFamily: "poppins" }}
                      value={option.mop}
                    >
                      {option.mop}
                    </Radio>
                  ))}
                </Radio.Group>
              </Col>
              <Col span={8} className="gutter-box">
                <div>
                  <span className="hintText color-black">Bank</span>
                </div>
                <Select
                  id="account_1"
                  disabled={formik.values.payment_mode_1 === "Cash"}
                  placeholder="Select an Account"
                  style={{ width: "75%", height: "38px" }}
                  options={accountList}
                  value={formik.values.account_1}
                  onChange={(e) => {
                    formik.setFieldValue("account_1", e);
                  }}
                />
              </Col>
              <Col span={8} className="gutter-box">
                <div>
                  <span className="hintText color-black">Payment 1</span>
                </div>
                <Input
                  type="text"
                  prefix="₹"
                  placeholder="0.00"
                  id="amount_1_received"
                  style={{ width: "100%", height: "38px" }}
                  value={formik.values["amount_1_received"]}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row gutter={16} justify="end" className="gutter-row">
              <Col span={8} className="gutter-box">
                <div>
                  <span
                    className="hintText color-black"
                    style={{ fontWeight: "600" }}
                  >
                    PAYMENT MODE 2
                  </span>
                  <Switch
                    checked={activatePM2}
                    onChange={(e) => {
                      if (!e) {
                        formik.setFieldValue("account_2", "");
                        formik.setFieldValue("amount_2_received", "");
                      }
                      setActivatePM2(e);
                    }}
                  ></Switch>
                </div>
                <Radio.Group
                  disabled={!activatePM2}
                  size="large"
                  style={{ marginTop: "5px", fontFamily: "Poppins" }}
                  onChange={(e) => {
                    formik.setFieldValue("payment_mode_2", e.target.value);
                  }}
                  value={formik.values.payment_mode_2}
                >
                  {paymentOptions.map((option, index) => (
                    <Radio
                      key={index}
                      style={{ fontSize: "13px", fontFamily: "poppins" }}
                      value={option.mop}
                    >
                      {option.mop}
                    </Radio>
                  ))}
                </Radio.Group>
              </Col>
              <Col span={8} justify="center" className="gutter-box">
                <div>
                  <span className="hintText color-black">Bank</span>
                </div>
                <Select
                  id="account_2"
                  disabled={
                    formik.values.payment_mode_2 === "Cash" || !activatePM2
                  }
                  placeholder="Select an Account"
                  style={{ width: "75%", height: "38px" }}
                  options={accountList}
                  value={formik.values.account_2}
                  onChange={(e) => {
                    formik.setFieldValue("account_2", e);
                  }}
                />
              </Col>
              <Col span={8} className="gutter-box">
                <div>
                  <span className="hintText color-black">Payment 2</span>
                </div>
                <Input
                  type="text"
                  disabled={!activatePM2}
                  prefix="₹"
                  placeholder="0.00"
                  id="amount_2_received"
                  style={{ width: "100%", height: "38px" }}
                  value={formik.values["amount_2_received"]}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row gutter={10} justify="end" className="gutter-row">
            <Col span={4} justify="center" className="gutter-box">
                <div>
                  <span className="hintText color-black">
                    Vendor Paid Weight (g)
                  </span>
                </div>
                <Input
                  type="text"
                  id="vendor_paid_weight"
                  style={{
                    width: "100%",
                    color: "#000000",
                    fontSize: "18px",
                    height: "38px",
                  }}
                  value={formik.values["vendor_paid_weight"]}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col span={4} justify="center" className="gutter-box">
                <div>
                  <span className="hintText color-black">
                    Purity (%)
                  </span>
                </div>
                <Input
                  type="text"
                  id="purity"
                  style={{
                    width: "100%",
                    color: "#000000",
                    fontSize: "18px",
                    height: "38px",
                  }}
                  value={formik.values["purity"]}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col span={4} justify="center" className="gutter-box">
                <div>
                  <span className="hintText color-black">
                    Old Gold Weight (g)
                  </span>
                </div>
                <Input
                  type="text"
                  id="old_gold_weight"
                  disabled={true}
                  style={{
                    width: "100%",
                    color: "#000000",
                    fontSize: "18px",
                    height: "38px",
                  }}
                  value={formik.values["old_gold_weight"]}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col span={8} className="gutter-box">
                <div>
                  <span className="hintText color-black">Weight (g)</span>
                </div>
                <Input
                  type="text"
                  id="calc_weight"
                  disabled={true}
                  style={{
                    width: "100%",
                    color: "#000000",
                    fontSize: "18px",
                    height: "38px",
                  }}
                  value={formik.values["calc_weight"]}
                />
              </Col>
            </Row>
          </>
        )
    }

    const step2 = () => {        
        return purchaseOrderTable(purchaseOrderList);
    };

    const step3 = () => {
        return (
            <>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={12} className="gutter-box">
                        <div style={{textAlign: 'right', height: '40px', backgroundColor: '#d3d3d3', border: '1px solid #d3d3d3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{fontFamily: 'poppins', fontSize: '15px', fontWeight: '700'}}>PAYMENT SUMMARY</span>                
                        </div>
                    </Col>
                </Row>
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={10} className="gutter-box">
                        <Row gutter={16} className="gutter-row">
                            <Col span={12} style={{textAlign: 'left'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Payment No</div>
                                <span>{formik.values.purchase_receipt_no}</span>
                            </Col>
                            <Col span={12} style={{textAlign: 'right'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Payment Date</div>
                                <span>{moment(formik.values.purchase_receipt_date).format('YYYY-MM-DD')}</span>
                            </Col>
                        </Row>
                        <Row gutter={16} className="gutter-row">
                            <Col span={12} style={{textAlign: 'left'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Vendor Name</div>
                                <span>{formik.values.vendor_name}</span>
                            </Col>
                            <Col span={12} style={{textAlign: 'right'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Closing Weight</div>
                                <span>{formik.values.closing_weight}</span>
                            </Col>
                        </Row>
                        <Row gutter={16} justify="center" className="gutter-row" style={{marginTop: '20px'}}>
                            <Col span={12} style={{fontSize: '14px', fontWeight: '700', textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                <span>PO Number</span>
                            </Col>
                            <Col span={12} style={{fontSize: '14px', fontWeight: '700', textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                <span>Paid Weight</span>
                            </Col>
                        </Row>
                        {selectedPurchaseOrderList.map(invoice => (
                            <Row gutter={16} justify="center" className="gutter-row">
                                <Col span={12} style={{textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                    <span>{invoice.purchase_order_no}</span>
                                </Col>
                                <Col span={12} style={{textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                                    <span>{invoice.paid_weight}</span>
                                </Col>
                            </Row>
                        ))}
                        <Row gutter={16} justify="center" className="gutter-row" style={{marginTop: '1px'}}>
                            <Col span={12} style={{fontSize: '15px', fontWeight: '700', textAlign: 'center', border: '1px dotted #d3d3d3'}} className="gutter-box">
                                <span>Total Receipt Weight</span>
                            </Col>
                            <Col span={12} style={{fontSize: '15px', fontWeight: '700', textAlign: 'center', border: '1px dotted #d3d3d3'}} className="gutter-box">
                                <span>{formik.values.total_receipt_weight}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }

    const rowSelection = {
        hideSelectAll: true,
        selectedRowKeys,
        onChange: (selectedKeys, selectedRows) => {
            setSelectedRowKeys(selectedKeys);
            setSelectedPurchaseOrderList(selectedRows);
        },
        onSelect: (record, selected) => {
            const updatedPurchaseOrderList = [...purchaseOrderList]; // Create a copy to ensure immutability
            const totalWgtInCustomerWallet = totalWeightInCustomerWallet();
            const totalWgtPaidForSelectedBills = totalWeightPaidForSelectedBills();
            const selectedPaidWeight = record.po_weight;
    
            if (selected) {
                let possibleWeight = 0;
                let isPending = false;
    
                // Calculate possible weight
                if (totalWgtInCustomerWallet < totalWgtPaidForSelectedBills + selectedPaidWeight) {
                    possibleWeight = totalWgtInCustomerWallet - totalWgtPaidForSelectedBills;
                    isPending = true;
                } else {
                    possibleWeight = selectedPaidWeight;
                }
    
                // Notification and prevention
                if (possibleWeight <= 0) {
                    openErrorNotification('Total paid weight exceeds available customer payment.');
                    return;
                }
    
                // Update the selected record
                updatedPurchaseOrderList.forEach((po) => {
                    if (po.purchase_order_no === record.purchase_order_no) {
                        po.paid_weight = possibleWeight.toFixed(2); // Ensure consistent decimal formatting
                        po.pending_weight = (po.po_weight - possibleWeight).toFixed(2);
                    }
                });
            } else {
                // Handle deselection: reset weights for the unselected record
                updatedPurchaseOrderList.forEach((po) => {
                    if (po.purchase_order_no === record.purchase_order_no) {
                        po.paid_weight = 0;
                        po.pending_weight = po.po_weight;
                    }
                });
            }
    
            // Update state
            setPurchaseOrderList(updatedPurchaseOrderList);
        },    
    };
    
    

    const mergedColumns = purchasePaymentColumns.map((col) => {
		if (!col.editable) {
		  return col;
		}
		return {
		  ...col,
		  onCell: (record) => ({
			record,
			inputType: 'text',
			dataIndex: col.dataIndex,            
			title: col.title,
			editing: isEditing(record),
		  }),
		};
	});

    const purchaseOrderTable = (datasoure) => {
        return datasoure.length > 0 ? (
            <>
                <Row gutter={16} className="gutter-row sales-list">
                    <Col span={24} className="gutter-box">
                    <Form form={form} component={false}>
                        <Table                       
    rowSelection={{ type: 'checkbox', ...rowSelection }}
    components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            dataSource={datasoure}
                            columns={mergedColumns}
                            pagination={false}
                            rowKey="purchase_order_no"
                            rowClassName="editable-row"
                            size='small'
                            bordered="true"						
                            scroll={{ y: 250 }}
                            style={{fontFamily: 'Poppins', fontSize: '18px', height: '250px'}}/>
                    </Form>

                    </Col>
                </Row>
            </>
        ) : null
    }

    const createNewFormSection = () => {
		return (
			<>
                {/* <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div style={{textAlign: 'right', height: '40px', backgroundColor: '#F5F5F5', border: '1px solid #d3d3d3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{fontFamily: 'poppins', fontSize: '15px', fontWeight: '700'}}>VENDOR PAYMENT</span>                
                        </div>
                    </Col>
                </Row> */}
                <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <Steps current={current} items={items} style={{margin: '15px 0px'}} />
                    </Col>
                </Row>
                <Row gutter={16} justify={"center"} className="gutter-row">
                    <Col span={24}>
                        {current === 0 && step1()}
                        {current === 1 && step2()}
                        {current === 2 && step3()}
                    </Col>
               </Row>
               <Row gutter={16} className="gutter-row">
                            <Col span={2} className="gutter-box">
                            {current > 0 && (<Button 
                                    type='primary' 
                                    style={{marginTop: '15px', width: '100%'}} 
                                    onClick={() => {
                                        prev()
                                    }}
                                    >
                                        Back
                                </Button>)}
                            </Col>
                            <Col span={2} offset={20} className="gutter-box">
                            {current < steps.length - 1 && (<Button 
                                    type='primary' 
                                    style={{marginTop: '15px', width: '100%'}} 
                                    onClick={() => {
                                        if(current === 0) {
                                            if(formik.values.vendor_id === '') {
                                                openErrorNotification('Please select a vendor to proceed');
                                                return;
                                            }
                                            if(formik.values.old_gold_weight === '' || formik.values.old_gold_weight === 0) {
                                                if(formik.values.account_1 === '' && 'Cash' !== formik.values.payment_mode_1) {
                                                    openErrorNotification('Please Select a Bank For Payment 1');
                                                    return;
                                                }
                                                if(activatePM2 && formik.values.account_2 === '') {
                                                    openErrorNotification('Please Select a Bank For Payment 2');
                                                    return;
                                                }
                                                const amount_2_received = parseFloat(formik.values.amount_2_received || 0);
                                                if(activatePM2 && 0 === amount_2_received) {
                                                    openErrorNotification('Please enter payment 2 else disable it !');
                                                    return;
                                                }
                                                const amount_1_received = parseFloat(formik.values.amount_1_received || 0);
                                                if(1 > amount_1_received) {
                                                    openErrorNotification('Minimum Payment Should be > 1 INR');
                                                    return;
                                                }
                                                const rate = parseFloat(formik.values.rate_per_gram || 0);
                                                if(1 > rate) {
                                                    openErrorNotification('Minimum Rate Should be > 1 INR');
                                                    return;
                                                }
                                            }
                                        }
                                        if(current === 1) {
                                           if(selectedPurchaseOrderList.length === 0 && parseFloat(formik.values.closing_weight==0)) {
                                                openErrorNotification('No bills selected for payment...');
                                                return;
                                           }
                                           const poList = purchaseOrderList.filter(po => po.paid_weight > 0);
                                           setPurchaseOrderList(poList);
                                        }
                                        next()
                                    }}
                                    >
                                        Next
                                </Button>)}
                            </Col>
                        </Row>
			</>
		);
	};

	return (
		<>
		<PageTitle
			imagesrc={VendorImage}
			pageTitle="Purchase Payment"
            buttonList={[{
                buttonName: 'Back',
                className: 'btn-back',
                url: '/slj/purchase-receipt'
            }]}
		/>
		    {contextHolder}
            <div className="content-area">
                <div className="formAreaStyle">
                    {/* <Badge.Ribbon text="Vendor Payment" placement='start' color="volcano"> */}
                        <div>
                            { createNewFormSection() }
                        </div>
                    {/* </Badge.Ribbon> */}
                </div>
            </div>
            {current === 2 && (
			<div className="formSubmitArea">
				<div className="formSubmitInnerArea">
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: 'grey' }}
						onClick={formik.handleReset}
					>
						Reset Fields
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ width: '170px', backgroundColor: '#1f43e5' }}
						onClick={formik.handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
            )}
		</>
	);


}

export default PurchasePayment;