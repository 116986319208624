import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, Row, notification, Select } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import VendorImage from '../../assets/images/vendor.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import vendorFormFields from './vendorFormFields';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';

const { Option } = Select;

const VendorForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [api, contextHolder] = notification.useNotification();
    const [uomList, setUOMList] = useState([]);

    useEffect(() => {
        httpCall({
            method: 'GET',
            url: '/api/uom/get',
        })
            .then((response) => {
                if (response) {
                    setUOMList(response?.data);
                } else {
                    console.error(
                        'Error retrieving category list',
                        response.message
                    );
                }
            })
            .catch((error) => {
                console.error('Error retrieving item type list', error);
            });
    }, []);

    const openErrorNotification = (description) => {
        api.error({
            message: 'Error!',
            duration: 4,
            description: description,
        });
    };

    const validatePhoneNumber = (value) => {
        if (value && !/^\d*$/.test(value)) {
            return 'Phone number must be numeric';
        }
        if (value && value.length < 10) {
            return 'Phone number must be exactly 10 digits';
        }
        if (value && value.length > 10) {
            return 'Phone number cannot exceed 10 digits';
        }
        return '';
    };

    const validatePANCard = (value) => {
        if (value && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
            return 'PAN card must be in the format ABCDE1234F';
        }
        return '';
    };

    const validateEmail = (value) => {
        if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return 'Invalid email address';
        }
        return '';
    };

    const validateAccountNumber = (values) => {
        if (values.account_no !== values.confirm_account_no) {
            return 'Account numbers do not match';
        }
        return '';
    };
    

    const formik = useFormik({
        initialValues: {
            company_name: '',
            mailing_name: '',
            address: '',
            country: '',
            state: '',
            city: '',
            zip_code: '',
            website: '',
            pan_card_no: '',
            gst_register_type: '',
            gst_no: '',
            email: '',
            mobile_number: '',
            phone_number: '',
            credit_days: '',
            vendor_job_type: '',
            contact_name: '',
            designation: '',
            department: '',
            contact_mobile_number: '',
            contact_email: '',
            experience: '',
            education_details: '',
            account_holder_name: '',
            account_no: '',
            confirm_account_no: '',
            ifsc_code: '',
            bank_name: '',
            branch: '',
            pending_weight: 0,
            pending_weight_unit: 'g',
        },
        validate: (values) => {
            const errors = {};
            if (!values.company_name) {
                errors.company_name = 'Required';
            }
            if (!values.mobile_number) {
                errors.mobile_number = 'Required';
            } else if (validatePhoneNumber(values.mobile_number)) {
                errors.mobile_number = validatePhoneNumber(values.mobile_number);
            }
            if (validatePANCard(values.pan_card_no)) {
                errors.pan_card_no = validatePANCard(values.pan_card_no);
            }
            if (validateEmail(values.email)) {
                errors.email = validateEmail(values.email);
            }
            if (validateAccountNumber(values)) {
                errors.confirm_account_no = validateAccountNumber(values);
            }
            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            if (location?.state?.id) {
                updateVendor(values, resetForm);
            } else {
                createVendor(values, resetForm);
            }
        },
    });

    useEffect(() => {
        if (location?.state?.id) {
            httpCall({
                method: 'GET',
                url: '/api/vendor/getVendor?id=' + location?.state?.id,
            })
                .then((response) => {
                    formik.setValues(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, []);

    useEffect(() => {
        formik.setFieldValue('mailing_name', formik.values.company_name);
    }, [formik.values.company_name]);

    const openNotification = (description) => {
        api.open({
            message: 'Success!',
            duration: 2,
            description: description,
            icon: <CheckCircleFilled style={{ color: '#808000' }} />,
        });
    };

    const createVendor = (values, handleReset) => {
        httpCall({
            method: 'POST',
            url: '/api/vendor/create',
            payload: values,
        })
            .then((response) => {
                if (response && response.status === 201) {
                    openNotification("Vendor Created Successfully. Go to the list view or create another one.");
                    handleReset();
                } else if (response && response.status === 400) {
                    openErrorNotification(response.data.error || "Mobile number already exists.");
                } else {
                    openErrorNotification("Failed to create vendor. Please try again.");
                }
            })
            .catch((error) => {
                console.error(error);
                api.error({
                    message: "Duplicate Entry Error",
                    description: "A Vendor with this mobile number already exists.",
                    duration: 4,
                });
            });
    };

    const updateVendor = (values, handleReset) => {
        httpCall({
            method: 'PUT',
            url: '/api/vendor/edit',
            payload: { ...values, vendorId: location?.state?.id },
        })
            .then((response) => {
                if (response) {
                    openNotification('Vendor Details Updated Successfully, Goto list view.');
                    setTimeout(() => navigate('/slj/vendor'), 2500);
                    handleReset();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleNumericInput = (event) => {
        const { value, id } = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            formik.setFieldValue(id, value);
        }
    };
    

    const createNewFormSection = (sections) => {
        return (
            <>
                {sections.map((section, index) => (
                    <Row key={index} gutter={16} className="gutter-row">
                        {section.map((field, index) => (
                            <Col span={field.colSpan} className="gutter-box" key={index}>
                                <div>
                                    <span className="hintText">{field.label}</span>
                                    {field.isRequired && (
                                        <span className="mandatory"></span>
                                    )}
                                </div>
    
                                {field.id === 'pending_weight_unit' ? (
                                    <Select
                                    id={field.id}
                                    name={field.id}
                                    style={{ width: "100%", height: "38px" }}
                                    onChange={(value) => formik.setFieldValue(field.id, value)}
                                    value={formik.values[field.id]}
                                    disabled
                                >
                                    {uomList.length > 0 ? (
                                        uomList.map((unit) => (
                                            <Option key={unit.id} value={unit.symbol}>
                                                {unit.symbol}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value="g" disabled>
                                            g
                                        </Option>
                                    )}
                                </Select>
                                
                                ) : field.id === 'gst_register_type' ? (
                                    <Select
                                        id={field.id}
                                        name={field.id}
                                        style={{ width: "100%", height: "38px" }}
                                        onChange={(value) => formik.setFieldValue(field.id, value)}
                                        value={formik.values[field.id]}
                                    >
                                        {['Regular', 'Composition', 'Unregistered', 'Consumer'].map((type) => (
                                            <Option key={type} value={type}>{type}</Option>
                                        ))}
                                    </Select>
                                ) : field.type === 'text' || field.type === 'number' ? (
                                    <Input
                                        type={field.id === 'pending_weight'}
                                        id={field.id}
                                        name={field.id}
                                        style={{ width: '100%' }}
                                        disabled={location?.state?.id && (field.id === 'mobile_number' || field.id === 'mailing_name')}
                                        onChange={field.id === 'pending_weight' ? handleNumericInput : formik.handleChange}
                                        value={formik.values[field.id]}
                                    />
                                ) : (
                                    <Checkbox
                                        id={field.id}
                                        style={{ width: '100%' }}
                                        onChange={formik.handleChange}
                                        checked={formik.values[field.id]}
                                    />
                                )}
                                {formik.touched[field.id] && formik.errors[field.id] && (
                                    <div style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '4px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <ExclamationCircleFilled style={{ color: 'red', marginRight: '8px' }} />
                                        {formik.errors[field.id]}
                                    </div>
                                )}
                            </Col>
                        ))}
                    </Row>
                ))}
            </>
        );
    };
    
    
    return (
        <>
            <PageTitle
                imagesrc={VendorImage}
                pageTitle={location?.state?.id ? "Update Vendor" : "Create Vendor"}
                buttonList={[{
                    buttonName: 'Back',
                    className: 'btn-back',
                    url: '/slj/vendor'
                }]}
            />
            {contextHolder}
            <div className="content-area">
                <div className="formAreaStyle">
                    {createNewFormSection(vendorFormFields.section1)}
                    <div className="DetailsBox">
                        <div className="Tabs">
                            <div
                                className={`individualTab ${activeTab === 1 ? 'active' : ''}`}
                                onClick={() => setActiveTab(1)}
                            >
                                Contact Details
                            </div>
                            <div
                                className={`individualTab ${activeTab === 2 ? 'active' : ''}`}
                                onClick={() => setActiveTab(2)}
                            >
                                Bank Details
                            </div>
                        </div>
                        {activeTab === 1 && (
                            <div className="individualTabDetails">
                                {createNewFormSection(vendorFormFields.section2)}
                            </div>
                        )}
                        {activeTab === 2 && (
                            <div className="individualTabDetails">
                                {createNewFormSection(vendorFormFields.section3)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '170px', backgroundColor: 'grey' }}
                        onClick={formik.handleReset}
                    >
                        Reset Fields
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '170px', backgroundColor: '#1f43e5' }}
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    );
};
    
export default VendorForm;