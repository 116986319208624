import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Menu.css';
import ImageLogo from '../assets/images/enterot-logo.png';
import ImageLogoText from '../assets/images/enterot-logo-text.png';
import ImageMaster from '../assets/images/master.svg';
import ImageCostCenter from '../assets/images/cost-center.svg';
import ImageReports from '../assets/images/report.svg';
import ImageStockTransfer from '../assets/images/stock-transfer.svg';
import ImageEmployee from '../assets/images/employee.svg';
import ImageMenuRightArw from '../assets/images/right-arrow.png';
import ImageMenuLeftArw from '../assets/images/left-arrow.png';
import ImageSales from '../assets/images/sales.svg';
import ImageSalesReport from '../assets/images/sales_report.svg';
import ImagePurchaseReport from '../assets/images/purchase.svg';
import ImageEstimate from '../assets/images/estimate.svg';
import ImageMaterialReturen from '../assets/images/material-return.svg';
import ImageCustomerReceipt from '../assets/images/customer_receipt.svg';
import ImageInventoryT from '../assets/images/inventory_T.svg';
import ImageStockTransferT from '../assets/images/stock_transfer.svg';
import ImageVendorPayment from '../assets/images/vendor_payment.svg';
import ImageMCPayment from '../assets/images/mc_payment.svg';
import ImageRole from '../assets/images/role.svg';
import ImageRoleCtrl from '../assets/images/role-ctrl.svg';
import ImageScreenAccessCtrl from '../assets/images/screen-access-ctrl.svg';
import ImageItemMenu from '../assets/images/item_menu.svg';
import ImageUOM from '../assets/images/uom.svg';
import ImageGoldSmith from '../assets/images/gold-smith.svg';
import ImageVendor from '../assets/images/vendor.svg';
import Imagesettlement from '../assets/images/settlement.png';
import ImageCustomer from '../assets/images/customer.svg';
import ImagePurchase from '../assets/images/material-return.svg';
import ImageTouchMap from '../assets/images/touch-mapping.svg';
import ImagePlant from '../assets/images/plant.svg';
import ImageSettings from '../assets/images/settings.svg';

const MenuItem = ({ title, submenus, icon, ismCollapsed, isOpen, onMenuClick, selectedSubmenuIndex, onSubmenuClick }) => {
  const navigate = useNavigate();

  const handleSubmenuClick = (submenuIndex, submenuPath) => {
    onSubmenuClick(submenuIndex);
    navigate(`/slj/${submenuPath}`);
  };

  return (
    <div className="menu-list">
      <div
        className={`menu-item-header  ${isOpen ? 'c' : ''}`}
        onClick={onMenuClick}
      >
        <img className="icon" src={icon} alt={title} />
        {!ismCollapsed && <span className="menuTextSelected">{title}</span>}
      </div>
      {isOpen && (
        <div className={`menu-item-content ${ismCollapsed ? 'collapsed' : ''}`}>
          {submenus.map((submenu) => (
            <div
              className={`menu-sub ${
                selectedSubmenuIndex === submenu.index ? 'submenu-clicked' : ''
              }`}
              onClick={() => handleSubmenuClick(submenu.index, submenu.path)}
              key={submenu.index}
            >
              <img
                className="icon-sub"
                src={submenu.icon}
                alt={submenu.name}
              />
              {!ismCollapsed && 
                <span className="submenu">
                  {submenu.name}
                </span>
              }
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Menu = () => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [selectedSubmenuIndex, setSelectedSubmenuIndex] = useState(null);
  
  const navigate = useNavigate();
  const isLoggedIn = true;

  const menuItemsData = [
    {
      title: 'Master',
      submenus: [
        { name: 'Cost Center', path:'cost-center', icon: ImageCostCenter, index: '1.1' },
        { name: 'Employee', path:'employee', icon: ImageEmployee, index: '1.2'  },
        { name: 'Role', path:'role', icon: ImageRole, index: '1.3'  },
        { name: 'Role Control', path:'role-control', icon: ImageRoleCtrl, index: '1.4'  },
        { name: 'Access Control', path:'access-control', icon: ImageScreenAccessCtrl, index: '1.5'  },
        { name: 'Item', path:'items', icon: ImageItemMenu, index: '1.6'  },
        { name: 'UOM', path:'uom', icon: ImageUOM, index: '1.7'  },
        { name: 'Gold Smith', path:'goldsmith', icon: ImageGoldSmith, index: '1.8'  },
        { name: 'Vendor', path:'vendor', icon: ImageVendor, index: '1.9'  },
        { name: 'Customer', path:'customer', icon: ImageCustomer, index: '1.10'  },
        { name: 'Settlement', path:'settlementlist', icon: Imagesettlement, index: '1.11'  },
        { name: 'Touch Mapping', path:'touch-mapping', icon: ImageTouchMap, index: '1.12'  },
        { name: 'Plant', path:'plant', icon: ImagePlant, index: '1.13'  },
        { name: 'Settings', path:'settings', icon: ImageSettings, index: '1.14'  },
      ],
      icon: ImageMaster,
    },
    {
      title: 'Transaction',
      submenus: [
        { name: 'Quotation', path:'quotation', icon: ImageSales, index: '2.1'  },
        { name: 'Estimate', path:'estimation', icon: ImageEstimate, index: '2.2'  },
        { name: 'Material Return', path:'material-return', icon: ImageMaterialReturen, index: '2.3'  },
        { name: 'Customer Receipt', path:'sales-receipt', icon: ImageCustomerReceipt, index: '2.4'  },
        { name: 'Purchase', path:'purchase', icon: ImagePurchase, index: '2.5'  },
        { name: 'Vendor Payment', path:'purchase-receipt', icon: ImageVendorPayment, index: '2.6'  },
        { name: 'MC Payment', path:'mc-receipt', icon: ImageMCPayment, index: '2.7'  },
        { name: 'Inventory', path:'stock', icon: ImageInventoryT, index: '2.8'  },
        { name: 'Stock Transfer', path:'stock-transfer', icon: ImageStockTransferT, index: '2.9'  },      
      ],
      icon: ImageStockTransfer,
    },
    {
      title: 'Report',
      submenus: [
        { name: 'Sales Report', path:'sales-report', icon: ImageSalesReport, index: '3.1'  },
        { name: 'Purchase Report', path:'purchase-report', icon: ImagePurchaseReport, index: '3.2'  },
        { name: 'Day Book Report', path:'day-book-report', icon: ImagePurchase, index: '3.3'  },  
        { name: 'Daily Stock Report', path:'stock-report', icon: ImagePurchase, index: '3.4'  }, 
      ],
      icon: ImageReports,
    },
  ];

  const toggleMenuCollapse = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  const handleMenuClick = (menuIndex) => {
    // If clicking the same menu item that's already open, close it
    if (activeMenuIndex === menuIndex) {
      setActiveMenuIndex(null);  // This will close the menu
    } else {
      setActiveMenuIndex(menuIndex);  // This will open the clicked menu
    }
  };

  const handleSubmenuClick = (submenuIndex) => {
    setSelectedSubmenuIndex(submenuIndex);
  };

  if (!isLoggedIn) {
    navigate('/');
    return null;
  }

  return (
    <div className={`menu ${isMenuCollapsed ? 'collapsed' : ''}`}>
      <div className="menu-top">
        {!isMenuCollapsed && (
          <div className="logo-container">
            <img
              alt="enterot"
              className="companyLogo"
              src={ImageLogo}
              onClick={() => navigate('/menu')}
            />
            <img
              alt="enterot"
              className="companyLogoText"
              src={ImageLogoText}
              onClick={() => navigate('/menu')}
            />
          </div>
        )}
        <div className="menu-toggle" onClick={toggleMenuCollapse}>
          <img
            alt="toggle"
            className="menuImage"
            src={!isMenuCollapsed ? ImageMenuLeftArw : ImageMenuRightArw}
          />
        </div>
      </div>

      <div className="menu-items">
        {menuItemsData.map((menuItem, index) => (
          <MenuItem
            key={index}
            title={menuItem.title}
            submenus={menuItem.submenus}
            icon={menuItem.icon}
            ismCollapsed={isMenuCollapsed}
            isOpen={activeMenuIndex === index.toString()}
            onMenuClick={() => handleMenuClick(index.toString())}
            selectedSubmenuIndex={selectedSubmenuIndex}
            onSubmenuClick={handleSubmenuClick}
          />
        ))}
      </div>
    </div>
  );
};

export default Menu;