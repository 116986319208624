import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Divider, Flex, Form, Input, InputNumber, Popconfirm, Radio, Row, Select, Space, Statistic, Steps, Switch, Table, Tag, Typography, notification } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, CalendarOutlined, CheckCircleFilled, DeleteFilled, EditOutlined, PlusOutlined, ProfileOutlined, ProfileTwoTone, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import SaleImage from '../../assets/images/customer_receipt.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import EditableCell from '../common/EditableCell';

const SalesReceiptCreate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [salesBillList, setSalesBillList] = useState([]);
  const [custNameOptions, setCustNameOptions] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [billOptions, setBillOptions] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [billList, setBillList] = useState([]);
  const [current, setCurrent] = useState(0);
  const [selectedBills, setSelectedBills] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activatePM2, setActivatePM2] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [paymentMode1, setPaymentMode1] = useState("Cash");
  const [account1, setAccount1] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.invoice_no === editingKey;

  const formatNumber = (value) => {
    const numValue = Math.max(0, parseFloat(value || 0)); // Prevent negative values
    return numValue.toFixed(2); // Always show 2 decimal places
  };

  const steps = [
    {
          title: 'Select Customer',
          content: 'First-content',
          icon: <UserOutlined />
    },
    {
          title: 'Select Bills To Pay',
          content: 'Second-content',
          icon: <SolutionOutlined />
    },
    {
          title: 'View Receipt Summary',
          content: 'Last-content',
    },
  ];
  const next = () => {
    if (current === 0) {
      const custAmount = parseFloat(formik.values.total_paid_amount || 0);
      if (custAmount < 1) {
        openErrorNotification("Minimum Payment Should be > 1 INR");
        return;
      }
    }
    if (current === 1) {
      if ((selectedBills.length === 0) && (parseFloat(formik.values.opening_balance).toFixed(2)=== 0.00)) {
        openErrorNotification("Please select a bill to proceed.");
        return;
      }
      setBillList(
        billList.map((bill) => {
          const selectedBill = selectedBills.find(
            (selected) => selected.invoice_no === bill.invoice_no
          );
          return selectedBill
            ? {
                ...bill,
                paid_amount: selectedBill.paid_amount,
                pending_amount: selectedBill.pending_amount,
              }
            : bill;
        })
      );
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
    if (current === 1) {
      // Resets bill list and selected bills when going back from bill selection
      fetchBillByCustomer(formik.values.customer_id);

      const resetBillList = billList.map((bill) => ({
        ...bill,
        paid_amount: "0.00",
        pending_amount: bill.bill_amount,
      }));

      setBillList(resetBillList);

      // Reset selected bills and row keys
      setSelectedBills([]);
      setSelectedRowKeys([]);

      // Reset the total sales receipt amount
      formik.setFieldValue("sales_receipt_amount", "0.00");
    }

    // Restore previous values
    formik.setFieldValue("customer_name", customerName);
    formik.setFieldValue("payment_mode_1", paymentMode1);
    formik.setFieldValue("account_1", account1);
    setActivatePM2(activatePM2);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));
      

  const formik = useFormik({
    initialValues: {
            invoice_receipt_no: '1',
            invoice_receipt_no_inc: '',  
            invoice_receipt_date: moment(),
            customer_id: '',
            customer_name: '',
            payment_mode_1: 'Cash',  
            account_1: '',
            payment_mode_2: 'Banking',
            account_2: '',
            paid_amount_1: '',
            paid_amount_2: '',
            cust_pending_amount: '',
            total_paid_amount: '',
            closing_balance:0.00,
            opening_balance:0.00
    },
		onSubmit: (values, {resetForm}) => {    
      // const pending_amount = parseFloat(formik.values.pending_amount || 0);
      // const advance_amount = parseFloat(formik.values.advance_amount || 0);

      // const advance = values.customer_advance_amount + advance_amount;
      // const pending = values.customer_pending_amount + pending_amount;

      // if(advance >= pending) {
      //     values.customer_advance_amount = advance - pending;
      //     values.customer_pending_amount = 0;
      // } else {
      //     values.customer_pending_amount = pending - advance;
      //     values.customer_advance_amount = 0;
      // }
      // values['billList'] = billList;
      // Set the calculated closing balance

            values['receiptItems'] = billList;
            values.invoice_receipt_date = moment(values.invoice_receipt_date).format('YYYY-MM-DD');    
      // console.log(values);
      createSalesRecipt(values);
    },
  });

  useEffect(() => {
        formik.setFieldValue('sales_receipt_amount', totalAmountPaidForSelectedBills());
  }, [billList]);

  useEffect(() => {
    if (current === 1) {
      // Restore previously selected bills and their amounts
      const updatedBillList = billList.map((bill) => {
        const selectedBill = selectedBills.find(
          (selected) => selected.invoice_no === bill.invoice_no
        );
        if (selectedBill) {
          return {
            ...bill,
            paid_amount: selectedBill.paid_amount,
            pending_amount: selectedBill.pending_amount,
          };
        }
        return bill;
      });
      setBillList(updatedBillList);
      setSelectedRowKeys(selectedBills.map((bill) => bill.invoice_no));
    }
  }, [current]);

  const billsFormik = useFormik({
    initialValues: {
            invoice_receipt_no: '',
            invoice_no: '',
            bill_amount: '',
            pending_amount: '',
            paid_amount: ''
		}
  });

  const openErrorNotification = (description) => {
    api.error({
		  message: 'Error !',
      duration: 4,
      description: description,
    });
  };

  const edit = (record) => {
    form.setFieldsValue({
      paid_amount: record.paid_amount,
      ...record,
    });
    setEditingKey(record.invoice_no);
  };
  

  const update = async (key) => {
    try {
      const row = await form.validateFields();
      const updated_paid_amount = Math.max(0, parseFloat(row.paid_amount || 0));
      const mBill = billList.find((bill) => bill.invoice_no === key);
  
      // Validate paid amount does not exceed bill amount
      if (updated_paid_amount > parseFloat(mBill.bill_amount)) {
        openErrorNotification("Paid Amount Per Bill Exceeds Bill Amount");
        cancel();
        return;
      }
  
      // Validate total payment against customer wallet
      const totalAmtInCustomerWallet = parseFloat(totalAmountInCustomerWallet());
      const currentPaidAmount = parseFloat(mBill.paid_amount || 0);
      const otherBillsTotal = parseFloat(totalAmountPaidForSelectedBills()) - currentPaidAmount;
  
      if (totalAmtInCustomerWallet < otherBillsTotal + updated_paid_amount) {
        openErrorNotification("Total Paid Amount Exceeds Customer Payment");
        cancel();
        return;
      }
  
      const updatedBillList = billList.map((bill) => {
        if (bill.invoice_no === key) {
          return {
            ...bill,
            paid_amount: formatNumber(updated_paid_amount),
            pending_amount: formatNumber(bill.bill_amount - updated_paid_amount),
            editable: true // Ensure the bill remains editable
          };
        }
        return bill;
      });
  
      // Automatically check the edited bill
      const updatedSelectedRowKeys = [...selectedRowKeys, key];
      const updatedSelectedBills = updatedBillList.filter(bill => updatedSelectedRowKeys.includes(bill.invoice_no));
  
      setBillList(updatedBillList);
      setSelectedBills(updatedSelectedBills);
      setSelectedRowKeys(updatedSelectedRowKeys);
  
      cancel();
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  

  const cancel = () => {
    setEditingKey("");
  };

  const totalPendingAmountPerVendor = (customerPendingBills) => {
    const total = customerPendingBills.reduce(
      (acc, next) => acc + Math.max(0, parseFloat(next.bill_amount || 0)),
      0
    );
    return formatNumber(total);
  };

  const totalAmountPaidForSelectedBills = () => {
    const total = billList.reduce(
      (acc, next) => acc + Math.max(0, parseFloat(next.paid_amount || 0)),
      0
    );
    return formatNumber(total);
  };

  const totalAmountInCustomerWallet = () => {
    const total = Math.max(
      0,
      parseFloat(formik.values.total_paid_amount || 0) +
        parseFloat(formik.values.customer_advance_amount || 0)
    );
    const closingBalanceAmount = closingBalanaceAmount();

    formik.setFieldValue("closing_balance",closingBalanceAmount);
    return formatNumber(total);
  };

  const closingBalanaceAmount = () => {
    const amount = parseFloat(formik.values.opening_balance || 0) - parseFloat(formik.values.total_paid_amount || 0);
    return amount < 0 ? 0 : amount;
  };
  
  

  useEffect(() => {
    fetchNextReceiptNo();
    fetchPaymentOptions();
    fetchCustomerList();
    fetchAccountList();
  }, []);

  const fetchAccountList = () => {
    setLoading(true);
    httpCall({
			method: 'GET',
			url: '/api/account/list',
    })
      .then((response) => {
        if (response) {
					setAccountList(response?.data.map(acc => ({label: `${acc.bank_name} - ${acc.account_no}`, value: acc.account_no})));
        } else {
					console.error(
						'Error retrieving account list',
						response.message
					);
        }
        setLoading(false);
      })
      .catch((error) => {
				console.error('Error retrieving account list', error);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //     const totalAmountGiven = parseFloat(formik.values.total_paid_amount || 0);
  //     const totalBillAmount = billList.reduce((acc, next) => acc + parseFloat(next.bill_amount), 0);
  //     const totalPaidAmount = billList.reduce((acc, next) => acc + parseFloat(next.paid_amount), 0);
  //     console.log(totalAmountGiven, totalBillAmount, totalPaidAmount)
  //     if((totalAmountGiven - totalPaidAmount) >= (totalBillAmount - totalPaidAmount)) {
  //         formik.setFieldValue('advance_amount', ((totalAmountGiven - totalPaidAmount) - (totalBillAmount - totalPaidAmount)).toFixed(3));
  //         formik.setFieldValue('pending_amount', 0);
  //     }
  //     if((totalAmountGiven - totalPaidAmount) < (totalBillAmount - totalPaidAmount)) {
  //         formik.setFieldValue('advance_amount', 0);
  //         formik.setFieldValue('pending_amount', ((totalBillAmount - totalPaidAmount) - (totalAmountGiven - totalPaidAmount)).toFixed(3));
  //     }
  // }, [billList]);

  useEffect(() => {
        if(formik.values.customer_id) {
      fetchBillByCustomer(formik.values.customer_id);
    }
  }, [formik.values.customer_id]);

  const fetchPaymentOptions = () => {
    httpCall({
			method: 'GET',
			url: '/api/payment/list?filter=true',
    })
      .then((response) => {
        setPaymentOptions(response?.data);
      })
      .catch((error) => {
				console.error('Error retrieving payment list:', error);
      });
  };

  const openNotification = (description) => {
    api.open({
		  message: 'Success !',
      duration: 2,
      description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
    });
  };

  const openAlertNotification = (description) => {
    api.error({
		  message: 'Error !',
      duration: 4,
          placement: 'top',
      description: description,
    });
  };

  const fetchNextReceiptNo = () => {
    httpCall({
			method: 'GET',
			url: '/api/sales/getInvoiceReceiptNr',
    })
      .then((response) => {
        if (response) {
                    formik.setFieldValue('invoice_receipt_no', `IR${response.data}/${moment().format('DDMMYYYY')}`);
                    formik.setFieldValue('invoice_receipt_no_inc', response.data);
        } else {
					console.error(
						'Error retrieving salses receipt no',
						response.message
					);
        }
      })
      .catch((error) => {
				console.error('Error retrieving salses receipt no', error);
      });
  };


  const createSalesRecipt = (values) => {
    httpCall({
			method: 'POST',
			url: '/api/sales/invoiceReceipt',
            payload: values
    })
      .then((response) => {
        if (response) {
          openNotification("Invoice Receipt Created Successfully !");
          // Navigate to the sales receipt list page
          navigate("/slj/sales-receipt");
        } else {
                    console.log('Error while creating invoice receipt');
        }
      })
      .catch((error) => {
        console.error("Error retrieving customer list:", error);
        // Optionally, show an error notification
        openAlertNotification("Failed to create invoice receipt");
      });
  };

  const fetchCustomerList = () => {
    httpCall({
			method: 'GET',
			url: '/api/customer/get',
    })
      .then((response) => {
        if (response) {
          setCustomerList(response?.data);
          setCustNameOptions(
            response?.data.map((c) => ({ label: c.company_name, value: c.id }))
          );
        } else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
        }
      })
      .catch((error) => {
				console.error('Error retrieving customer list:', error);
      });
  };

  const fetchBillByCustomer = (id) => {
    httpCall({
			method: 'GET',
			url: '/api/sales/getInvoiceDetails?customerId=' + id,
    })
      .then((response) => {
        if (response) {
          const list = response?.data.map((bill) => ({
            invoice_no: bill.invoice_no,
            bill_amount: bill.pending_amount,
            paid_amount: 0,
            pending_amount: bill.pending_amount,
            selected: false, // Add this line to initialize the selected flag
          }));
          setBillList([...list]);
          formik.setFieldValue(
            "cust_pending_amount",
            totalPendingAmountPerVendor([...list])
          );
        } else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
        }
      })
      .catch((error) => {
				console.error('Error retrieving customer list:', error);
      });
  };

  const salesBillColumns = [
    {
      title: 'Bill Nr',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      align: 'center',
      width: 250
    },
    {
      title: 'Bill Amount',
      dataIndex: 'bill_amount',
      key: 'bill_amount',
      align: 'center',
      width: 100,
      render: (value) => formatNumber(value)
    },
    {
      title: 'Paid Amount',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      align: 'center',
      editable: true,
      width: 150,
      render: (value, record) => formatNumber(value),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, record) => {
        const editable = isEditing(record); // Always allow editing
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => update(record.invoice_no)}
              style={{
                marginRight: 8,
              }}
            > Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <EditOutlined
            style={{
              cursor: "pointer", // Always enable cursor
              fontSize: "20px",
              color: "#08c", // Always color enabled
            }}
            onClick={() => edit(record)} // Always allow editing
          />
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (_, record) => {
        return record.bill_amount > record.paid_amount ? (
          <Tag color='#f50'>PENDING</Tag>
        ) : (<Tag color="#87d068">FULLY PAID</Tag>);
      }
    },
  ];
  

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);

        // Update selected bills
        setSelectedBills(
            newSelectedRows.map((row) => {
                const bill = billList.find(
                    (bill) => bill.invoice_no === row.invoice_no
                );
                return {
                    ...row,
                    paid_amount: bill ? bill.paid_amount : row.paid_amount,
                    pending_amount: bill ? bill.pending_amount : row.pending_amount,
                    editable: true, // Mark bill as editable when selected
                };
            })
        );

        // Update billList to reflect editable state
        const updatedBillList = billList.map((bill) => {
            const isSelected = newSelectedRowKeys.includes(bill.invoice_no);
            return {
                ...bill,
                editable: isSelected,
            };
        });

        setBillList(updatedBillList);
    },
    onSelect: (record, selected, selectedRows) => {
        const updatedBillList = [...billList];
        const totalAmtInCustomerWallet = parseFloat(totalAmountInCustomerWallet());
        const totalAmtPaidForSelectedBills = parseFloat(totalAmountPaidForSelectedBills());
        const selectedPaidAmt = parseFloat(record.bill_amount);

        if (selected) {
            // Calculate possible paid amount and adjust bill amounts
            const possibleAmount = Math.min(
                selectedPaidAmt,
                Math.max(0, totalAmtInCustomerWallet - totalAmtPaidForSelectedBills)
            );

            updatedBillList.forEach((bill) => {
                if (bill.invoice_no === record.invoice_no) {
                    bill.paid_amount = formatNumber(possibleAmount);
                    bill.pending_amount = formatNumber(Math.max(0, bill.bill_amount - possibleAmount));
                }
            });

            // Warning if exceeding
            if (possibleAmount <= 0 ) {
                openErrorNotification('Selected amount exceeds available wallet balance.');
            }
        } else {
            // Reset amounts when deselected
            updatedBillList.forEach((bill) => {
                if (bill.invoice_no === record.invoice_no) {
                    bill.paid_amount = "0.00";
                    bill.pending_amount = formatNumber(bill.bill_amount);
                }
            });
        }

        // Update state
        setBillList(updatedBillList);
        setSelectedBills(
            selectedRows.map((row) => {
                const bill = updatedBillList.find(
                    (bill) => bill.invoice_no === row.invoice_no
                );
                return {
                    ...row,
                    paid_amount: bill ? bill.paid_amount : "0.00",
                    pending_amount: bill ? bill.pending_amount : row.bill_amount,
                };
            })
        );
    },
    hideSelectAll: true,
};


  const mergedColumns = salesBillColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
			inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handlePaymentChange = (value, fieldName, otherFieldName) => {
    const numValue = Math.max(0, parseFloat(value || 0));
    formik.setFieldValue(fieldName, formatNumber(numValue));
    const otherAmount = parseFloat(formik.values[otherFieldName] || 0);
    formik.setFieldValue(
      "total_paid_amount",
      formatNumber(numValue + otherAmount)
    );
    const updatedBillList = billList.map((bill) => {
      if (bill.invoice_no === formik.values.invoice_no) {
        return {
          ...bill,
          paid_amount: formatNumber(numValue),
        };
      }
      return bill;
    });
    setBillList(updatedBillList);
  };

  const salesBillTable = (datasoure) => {
    return datasoure.length > 0 ? (
      <>
        <Row gutter={16} className="gutter-row sales-list">
          <Col span={24} className="gutter-box">
            <Form form={form} component={false}>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                  selectedRowKeys, // Use selectedRowKeys to keep selected rows checked
                }}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                dataSource={datasoure}
                columns={mergedColumns}
                pagination={false}
                rowKey="invoice_no"
                rowClassName="editable-row"
                            size='small'
                bordered="true"
                scroll={{ y: 250 }}
                            style={{fontFamily: 'Poppins', fontSize: '18px', height: '250px'}}/>
            </Form>

          </Col>
        </Row>
      </>
        ) : null
    }

  const step1 = () => {
    return (
      <>
        <Row gutter={16} justify="center" className="gutter-row">
          <Col span={24} className="gutter-box">
                        <div style={{textAlign: 'right'}}>
              <span className="hintText color-black">Receipt No</span>
            </div>
            <Input
              type="text"
              id="invoice_receipt_no"
              disabled={true}
              variant="borderless"
                            style={{ width: '100%', textAlign: 'right', color: '#000000', paddingLeft: '0px', fontSize: '14px'}}
                            value={formik.values['invoice_receipt_no']}
            />
          </Col>
        </Row>
        <Row gutter={16} justify="center" className="gutter-row">
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText color-black">Customer</span>
            </div>
            <Select
              id="customer_id"
              placeholder="Select Customer"
    style={{ width: '100%', height: '38px' }}
              options={custNameOptions}
              value={formik.values.customer_id}
              onChange={(e) => {
                formik.setFieldValue("customer_id", e);
                const customer = customerList.find((cust) => cust.id === e);
                formik.setFieldValue(
                  "customer_advance_amount",
                  parseFloat(customer.advance_amount || 0)
                );
                formik.setFieldValue("opening_balance",customer.pending_amount);
                formik.setFieldValue("customer_name", customer.company_name);
                setCustomerName(customer.company_name); // Update state
              }}
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText color-black">Total Pending Amount</span>
            </div>
            <Input
              disabled={true}
              type="text"
              id="cust_pending_amount"
                        style={{ width: '100%', color: 'green', fontSize: '18px', height: '38px' }}
              value={parseFloat(formik.values.opening_balance).toFixed(2)} // Format to 2 decimal places
            />
          </Col>
          <Col span={8} offset={2} className="gutter-box">
            <div>
              <span className="hintText color-black">Receipt Date</span>
            </div>
            <DatePicker
                            style={{ width: '100%', height: '38px' }}
              format={{
                                format: 'YYYY-MM-DD',
                                type: 'mask',
              }}
              value={formik.values.invoice_receipt_date}
              disabled={true}
              onChange={(date, dateString) => {
                formik.setFieldValue("invoice_receipt_date", date);
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} justify="end" className="gutter-row">
          <Col span={8} className="gutter-box">
            <div>
                            <span className="hintText color-black" style={{fontWeight: '600'}}>PAYMENT MODE 1</span>                            
            </div>
            <Radio.Group
              style={{ marginTop: "5px", fontSize: " 16px" }}
              onChange={(e) => {
                formik.setFieldValue("payment_mode_1", e.target.value);
                setPaymentMode1(e.target.value); // Update state
              }}
              value={formik.values.payment_mode_1} // Change this line
            >
              {paymentOptions.map((option, index) => (
                <Radio
                  key={index}
                  style={{ fontSize: "13px", fontFamily: "poppins" }}
                  value={option.mop}
                >
                  {option.mop}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText color-black">Bank</span>
            </div>
            <Select
              id="account_1"
                            disabled={formik.values.payment_mode_1 === 'Cash'}
              placeholder="Select an Account"
                            style={{ width: '75%', height: '38px' }}
              options={accountList}
              value={account1} // Use state value
              onChange={(e) => {
                formik.setFieldValue("account_1", e);
                setAccount1(e); // Update state
              }}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText color-black">Payment 1</span>
            </div>
            <Input
              type="text"
              prefix="₹"
                            placeholder='0.00'
              id="paid_amount_1"
                            style={{ width: '100%', height: '38px'}}
                            value={formik.values['paid_amount_1']}
              onChange={(e) => {
                const value = e.target.value;
                formik.setFieldValue("paid_amount_1", value);
                const paid_amount_2 = parseFloat(
                  formik.values.paid_amount_2 || 0
                );
                formik.setFieldValue(
                  "total_paid_amount",
                  paid_amount_2 + parseFloat(value || 0)
                );
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} justify="end" className="gutter-row">
          <Col span={8} className="gutter-box">
            <div>
              <span
                className="hintText color-black"
                style={{ fontWeight: "600" }}
              >
                PAYMENT MODE 2
              </span>
              <Switch
                checked={activatePM2}
                onChange={(e) => {
                  if (!e) {
                    formik.setFieldValue("paid_amount_2", "");
                    formik.setFieldValue("amount_2_received", "");
                  }
                  setActivatePM2(e);
                            }}>
                            </Switch>                    
            </div>
            <Radio.Group
              disabled={!activatePM2}
              size="large"
              style={{ marginTop: "5px", fontFamily: "Poppins" }}
              onChange={(e) => {
                formik.setFieldValue("payment_mode_2", e.target.value);
              }}
              value={formik.values.payment_mode_2}
            >
              {paymentOptions.map((option, index) => (
                <Radio
                  key={index}
                  style={{ fontSize: "13px", fontFamily: "poppins" }}
                  value={option.mop}
                >
                  {option.mop}
                </Radio>
              ))}
            </Radio.Group>
          </Col>
          <Col span={8} justify="center" className="gutter-box">
            <div>
              <span className="hintText color-black">Bank</span>
            </div>
            <Select
              id="account_2"
                            disabled={formik.values.payment_mode_2 === 'Cash' || !activatePM2}
              placeholder="Select an Account"
                            style={{ width: '75%', height: '38px' }}
              options={accountList}
              value={formik.values.account_2}
                            onChange={(e)=> {
                formik.setFieldValue("account_2", e);
              }}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText color-black">Payment 2</span>
            </div>
            <Input
              type="text"
              disabled={!activatePM2}
              prefix="₹"
                            placeholder='0.00'
              id="paid_amount_2"
                            style={{ width: '100%', height: '38px'}}
                            value={formik.values['paid_amount_2']}
              onChange={(e) => {
                const value = e.target.value;
                formik.setFieldValue("paid_amount_2", value);
                const paid_amount_1 = parseFloat(
                  formik.values.paid_amount_1 || 0
                );
                formik.setFieldValue(
                  "total_paid_amount",
                  paid_amount_1 + parseFloat(value || 0)
                );
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} justify="end" className="gutter-row">
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText color-black">Total Amount To Pay</span>
            </div>
            <InputNumber
              precision={2}
              disabled={true}
              id="total_paid_amount"
                            style={{ width: '100%', color: '#000000', fontSize: '18px', height: '38px'}}
                            value={parseFloat(formik.values['total_paid_amount'] || 0).toFixed(2)} // Format to 2 decimal places
            />
          </Col>
        </Row>
      </>
    );
    }

  const step2 = () => {
    return salesBillTable(billList);
    }

  const step3 = () => {
    const paidBills = billList.filter(
      (bill) => parseFloat(bill.paid_amount) > 0
    ); // Filter out unpaid bills
    return (
      <>
        <Row gutter={16} justify="center" className="gutter-row">
          <Col span={12} className="gutter-box">
                        <div style={{textAlign: 'right', height: '40px', backgroundColor: '#d3d3d3', border: '1px solid #d3d3d3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{fontFamily: 'poppins', fontSize: '15px', fontWeight: '700'}}>RECEIPT SUMMARY</span>                
            </div>
          </Col>
        </Row>
        <Row gutter={16} justify="center" className="gutter-row">
          <Col span={10} className="gutter-box">
            <Row gutter={16} className="gutter-row">
                            <Col span={12} style={{textAlign: 'left'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Receipt No</div>
                <span>{formik.values.invoice_receipt_no}</span>
              </Col>
                            <Col span={12} style={{textAlign: 'right'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Receipt Date</div>
                                <span>{moment(formik.values.invoice_receipt_date).format('YYYY-MM-DD')}</span>
              </Col>
            </Row>
            <Row gutter={16} className="gutter-row">
                            <Col span={12} style={{textAlign: 'left'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Customer Name</div>
                <span>{formik.values.customer_name}</span>
              </Col>
              <Col span={12} style={{textAlign: 'right'}} className="gutter-box">
                                <div style={{fontSize: '14px', paddingBottom: '8px', fontWeight: '600'}}>Closing Balance</div>
                <span>{formik.values.closing_balance}</span>
              </Col>
            </Row>
                        <Row gutter={16} justify="center" className="gutter-row" style={{marginTop: '20px'}}>
                            <Col span={12} style={{fontSize: '14px', fontWeight: '700', textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                <span>Invoice Number</span>
              </Col>
                            <Col span={12} style={{fontSize: '14px', fontWeight: '700', textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                <span>Paid Amount</span>
              </Col>
            </Row>
            {paidBills.map((invoice) => (
              <Row
                key={invoice.invoice_no}
                gutter={16}
                justify="center"
                className="gutter-row"
              >
                <Col
                  span={12}
                  style={{ textAlign: "center", border: "1px solid #d3d3d3" }}
                  className="gutter-box"
                >
                  <span>{invoice.invoice_no}</span>
                </Col>
                                <Col span={12} style={{textAlign: 'center', border: '1px solid #d3d3d3'}} className="gutter-box">
                  <span>{invoice.paid_amount}</span>
                </Col>
              </Row>
            ))}
                        <Row gutter={16} justify="center" className="gutter-row" style={{marginTop: '1px'}}>
                            <Col span={12} style={{fontSize: '15px', fontWeight: '700', textAlign: 'center', border: '1px dotted #d3d3d3'}} className="gutter-box">
                <span>Total Receipt Amount</span>
              </Col>
                            <Col span={12} style={{fontSize: '15px', fontWeight: '700', textAlign: 'center', border: '1px dotted #d3d3d3'}} className="gutter-box">
                <span>{formik.values.sales_receipt_amount}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
        )
    }
  const createNewFormSection = () => {
    return (
      <>
        {/* <Row gutter={16} justify="center" className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div style={{textAlign: 'right', height: '40px', backgroundColor: '#F5F5F5', border: '1px solid #d3d3d3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{fontFamily: 'poppins', fontSize: '15px', fontWeight: '700'}}>SALES RECEIPT</span>                
                        </div>
                    </Col>
                </Row> */}
        <Row gutter={16} justify="center" className="gutter-row">
          <Col span={24} className="gutter-box">
            <Steps
              current={current}
              items={items}
              style={{ margin: "15px 0px" }}
            />
          </Col>
        </Row>
        <Row gutter={16} justify={"center"} className="gutter-row">
          <Col span={24}>
            {current === 0 && step1()}
            {current === 1 && step2()}
            {current === 2 && step3()}
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={2} className="gutter-box">
            {current > 0 && (
              <Button
                type="primary"
                style={{ marginTop: "15px", width: "100%" }}
                onClick={() => {
                  prev();
                }}
              >
                Back
              </Button>
            )}
          </Col>
          <Col span={2} offset={20} className="gutter-box">
            {current < steps.length - 1 && (
              <Button
                type="primary"
                style={{ marginTop: "15px", width: "100%" }}
                onClick={() => {
                  if (current === 0) {
                    const custAmount = parseFloat(
                      formik.values.total_paid_amount || 0
                    );
                    if (1 > custAmount) {
                      openErrorNotification(
                        "Minimum Payment Should be > 1 INR"
                      );
                      return;
                    }
                  }
                  if (current === 1) {
                    if (selectedBills.length === 0) {
                      openErrorNotification("Please select a bill to proceed.");
                      return;
                    }
                                           const uList = billList.filter(bill => bill.paid_amount > 0);
                    setBillList(uList);
                  }
                                        next()
                }}
              >
                Next
                                </Button>)}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <PageTitle
        imagesrc={SaleImage}
        pageTitle="Sales Receipt"
            buttonList={[{
                buttonName: 'Back',
                className: 'btn-back',
                url: '/slj/sales-receipt'
            }]}
      />
      {contextHolder}
      <div className="content-area">
        <div className="formAreaStyle">{createNewFormSection()}</div>
      </div>
      {current === 2 && (
        <div className="formSubmitArea">
          <div className="formSubmitInnerArea">
            <Button
              type="primary"
              size="large"
						style={{ width: '170px', backgroundColor: 'grey' }}
              onClick={formik.handleReset}
            >
              Reset Fields
            </Button>
            <Button
              type="primary"
              size="large"
						style={{ width: '170px', backgroundColor: '#1f43e5' }}
              onClick={formik.handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </>
  );


}

export default SalesReceiptCreate;